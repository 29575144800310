import { fieldNameFromShortCodeExport } from "../../common/field-map";
import { exportFieldsList } from "../../resources/data/export-field-list";
import { useEffect, useState } from "react";
import React from "react";
import { Checkbox, CheckboxGroup, Input } from "rsuite";
import ExportFooter from "../../common/footer-export";
import RwireSaveTemplate from "./save-as-template";
import RwireLoadFromTemplate from "./load-from-template";
import RwireSaveExistingTemplate from "./save-as-existing";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";
import CloseIcon from "@rsuite/icons/Close";
import ButtonList from "./buttonList";
import SortableFields from "./sortable-fields";

let searchTimer = null;

const RwireExport = (props) => {
  const {
    onSetSelectedField,
    selectedFields,
    totalRecordsCount,
    selectedRows,
    setExportToggle,
    exportToggle,
    onGetExportTemplates,
  } = props;
  const [query, setQuery] = useState("");
  const [fieldOptions, setFieldOptions] = useState([]);
  const [defaultOption, setDefaultData] = useState([]);
  const [value, setValue] = useState([]);
  const [error, setError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [check, setCheck] = useState();
  const [loadModal, setLoadModal] = useState(false);
  const [loadSaveModal, setSaveLoadModal] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState("");
  const [showExportMailPopup, setshowExportMailPopup] = useState(false);
  const [existingTemplateModal, setExistingTemplateModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleshowExportMailPopup = () => {
    setshowExportMailPopup(true);
  };
  const closeExportMailPopup = () => {
    setshowExportMailPopup(false);
  };

  const handleSaveTemplate = (template) => {
    setTemplates([...templates, template]);
  };

  const handleLoadTemplate = (selectedFields) => {
    props.onSetSelectedField({ selectedFields });
    setValue(selectedFields);
  };

  const handleSelectTemplate = (templateName) => {
    setSelectedTemplateName(templateName);
    handleClickModalLoadSave();
  };
  const [isSearching, setIsSearching] = useState(false);

  const handleClickModalLoad = () => {
    setLoadModal(!loadModal);
  };

  const handleClickExistingModalLoad = () => {
    setExistingTemplateModal(!existingTemplateModal);
  };

  const handleClickModalLoadSave = () => {
    setSaveLoadModal(!loadSaveModal);
  };

  useEffect(() => {
    setFieldOptions(exportFieldsList);
    setDefaultData(exportFieldsList);
    setValue(selectedFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (event) => {
    setQuery(event);
    clearTimeout(searchTimer);
    searchTimer = setTimeout(() => {
      const filteredData = defaultOption.filter((i) => {
        if (
          event === "" ||
          fieldNameFromShortCodeExport(i)
            .toLowerCase()
            .includes(event.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      });
      setFieldOptions(filteredData);
    }, 500);
    setIsSearching(event !== "");
    if (event === "") {
      setValue(selectedFields);
    }
    setSelectedCategory("");
  };

  const hideError = () => {
    setTimeout(() => {
      setError(null);
    }, 3000);
  };

  const handleSelectField = (e) => {
    if (e.length === 0) {
      e = ["PN_B"];
      setError("Please select at least one field");
      hideError();
    } else {
      setError(null);
    }
    setValue(e);
    onSetSelectedField({ selectedFields: e });
  };

  const handleCheckAll = (value, checked) => {
    setCheck(checked);
    let updatedSelectedFields;

    if (checked) {
      updatedSelectedFields = [
        "PN_B",
        ...fieldOptions.filter((field) => field !== "PN_B"),
      ];
    } else {
      updatedSelectedFields = ["PN_B"];
    }

    onSetSelectedField({ selectedFields: updatedSelectedFields });
    setValue(updatedSelectedFields);
  };

  const clearAll = (check) => {
    if (check) {
      const updatedSelectedFields = selectedFields.includes("PN_B")
        ? ["PN_B"]
        : [];
      onSetSelectedField({ selectedFields: updatedSelectedFields });
      setValue(updatedSelectedFields);
    }
  };

  const handleRemoveItem = (item) => {
    if (item === "PN_B") {
      return;
    }
    const filteredItem = selectedFields.filter((field) => field !== item);
    onSetSelectedField({ selectedFields: filteredItem });
    setValue(filteredItem);
  };

  const onDragEnd = (result) => {
    console.log("Drag result:", result);
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const reorderedFields = Array.from(selectedFields);
    const [removed] = reorderedFields.splice(source.index, 1);
    reorderedFields.splice(destination.index, 0, removed);

    onSetSelectedField({ selectedFields: reorderedFields });
    setValue(reorderedFields);
  };

  return (
    <>
      <RwireSaveTemplate
        loadModal={loadModal}
        setLoadModal={setLoadModal}
        selectedFields={value}
        {...props}
        fieldNameFromShortCodeExport={fieldNameFromShortCodeExport}
        onSaveTemplate={handleSaveTemplate}
        onGetExportTemplates={onGetExportTemplates}
      />
      <RwireLoadFromTemplate
        loadSaveModal={loadSaveModal}
        setSaveLoadModal={setSaveLoadModal}
        {...props}
        fieldNameFromShortCodeExport={fieldNameFromShortCodeExport}
        templates={templates}
        onLoadTemplate={handleLoadTemplate}
      />
      <RwireSaveExistingTemplate
        existingTemplateModal={existingTemplateModal}
        setExistingTemplateModal={setExistingTemplateModal}
        {...props}
        fieldNameFromShortCodeExport={fieldNameFromShortCodeExport}
        templates={templates}
        onSelectTemplate={handleSelectTemplate}
      />
      <div className="modal-expert">
        <div className="export-headname d-flex justify-content-between">
          <div className="export_count">
            Exporting{" "}
            {selectedRows.length === 0
              ? `${totalRecordsCount + "/" + totalRecordsCount}`
              : `${selectedRows.length + "/" + totalRecordsCount}`}{" "}
            Results
          </div>
          <div
            className="cursor-pointer"
            title="Close"
            onClick={() => setExportToggle(!exportToggle)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="export-default-fame">
            <div className="d-flex justify-content-between">
              <div className="d-flex ">
                <Input
                  className="export-search-one "
                  placeholder={`Search For Fields`}
                  onChange={handleSearchChange}
                  value={query}
                />
                <h6
                  className="clearAll-btn load-from-template"
                  onClick={() => handleSelectTemplate(selectedTemplateName)}
                >
                  Load from template
                </h6>
              </div>
              <div className="d-flex justify-content-between align-content-clear">
                <div>
                  <h6 className="clearAll-btn clearAll" onClick={clearAll}>
                    Clear All
                  </h6>
                </div>
                <div className="select-all">
                  <Checkbox
                    checked={
                      isSearching ? false : value.length === fieldOptions.length
                    }
                    onChange={handleCheckAll}
                    className="clearAll-btn mr-4"
                  >
                    Select All
                  </Checkbox>
                </div>
              </div>
            </div>

            <div className="filters-sublist-export mt-3 px-0 pb-3 pt-0 list-checkbox">
              <ButtonList
                onSetFieldOptions={setFieldOptions}
                selectedCategory={selectedCategory}
                onSetSelectedCategory={setSelectedCategory}
              />

              <CheckboxGroup
                name="checkboxList"
                value={value}
                onChange={handleSelectField}
              >
                <ul className="filter-names-export d-flex flex-wrap">
                  {fieldOptions.map((item) => (
                    <Checkbox
                      key={item}
                      value={item}
                      checked={value.includes(item)}
                    >
                      {fieldNameFromShortCodeExport(item)}
                    </Checkbox>
                  ))}
                </ul>
              </CheckboxGroup>
            </div>
            {error && (
              <div className="error-message-export-checkbox pt-1 pl-2">
                {error}
              </div>
            )}
          </div>
          <div className="export-default-fame-second">
            <div className="d-flex justify-content-end justify-content-center">
              <h6 className="save-as-lable text-nowrap">Save As: </h6>
              <h6
                className="clearAll-btn save-as-template text-nowrap"
                onClick={() => handleClickModalLoad()}
              >
                New Template
              </h6>
              <h6
                className="clearAll-btn save-as-template justify-content-center existing-margin"
                onClick={() => handleClickExistingModalLoad()}
              >
                Existing
              </h6>
            </div>
            <div className="filters-sublist-export filter-select-filed-list mt-3">
              <div className="select-filed-export">
                Selected Fields ({selectedFields.length})
              </div>
              <div className="dnd-export">
                <SortableFields
                  selectedFields={selectedFields}
                  onDragEnd={onDragEnd}
                  handleRemoveItem={handleRemoveItem}
                  fieldNameFromShortCodeExport={fieldNameFromShortCodeExport}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ExportFooter
        setValue={setValue}
        showExportMailPopup={showExportMailPopup}
        handleshowExportMailPopup={handleshowExportMailPopup}
        closeExportMailPopup={closeExportMailPopup}
        {...props}
      />
    </>
  );
};

export default RwireExport;
