import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getProjectDashboard,
  getReportProjectString,
} from "../../action/report/report-dashboard";
import { useEffect } from "react";
import {
  getReportProjectDataById,
  getReportProjectNameById,
  setProjectReport,
} from "../../action/report/report-project";
import { getReportProjectNotes } from "../../action/report/report-notes";
import {
  addUserToProject,
  createAddUserRequests,
  getAllExistingOrganizationalUsers,
  getAllProjectUsers,
  getUserDetailsUsingId,
  removeAccessOfUserFromProject,
  sendInvitationToCollaborator,
  updateRoleOfUserInProject,
} from "../../action/report/report-users";
import { useNavigate } from "react-router-dom";
import { addNewUserToRwire } from "../../action/user";
import UsersPage from "../../components/report/rwire-report-dashboard/users/users-page";

const Container = (props) => {
  const { onGetAllExistingOrganizationalUsers, onGetAllProjectUsers } = props;
  const navigate = useNavigate();
  const projectId = sessionStorage.getItem("projectId");

  useEffect(() => {
    const fetchData = async () => {
      if (projectId) {
        await onGetAllProjectUsers(projectId);
        await onGetAllExistingOrganizationalUsers();
      }
    };
    fetchData();
  }, [projectId, onGetAllProjectUsers, onGetAllExistingOrganizationalUsers]);
  if (!projectId) {
    navigate("/en/existing-project");
  }
  return <UsersPage {...props} />;
};

const mapStateToProps = ({
  reportDashboard,
  reportNotes: { reportTableData: keyFeaturesList = [] },
  project,
  app: { userName, userRole },
}) => ({
  ...reportDashboard,
  keyFeaturesList,
  ...project,
  userName,
  userRole,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetProjectDashboard: getProjectDashboard,
      onGetReportProjectString: getReportProjectString,
      onGetReportProjectDataById: getReportProjectDataById,
      onSetProjectReport: setProjectReport,
      onGetReportProjectNotes: getReportProjectNotes,
      onGetReportProjectNameById: getReportProjectNameById,
      onAddUsersToProject: addUserToProject,
      onGetAllProjectUsers: getAllProjectUsers,
      onUpdateRoleOfUserInProject: updateRoleOfUserInProject,
      onRemoveAccessOfUserFromProject: removeAccessOfUserFromProject,
      onGetAllExistingOrganizationalUsers: getAllExistingOrganizationalUsers,
      onGetUserDetailsUsingId: getUserDetailsUsingId,
      onAddNewUserToRwire: addNewUserToRwire,
      onCreateAddUserRequests: createAddUserRequests,
      onSendInvitationToCollaborator: sendInvitationToCollaborator,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
