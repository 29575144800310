/* eslint-disable no-useless-escape */
export const setHighlight = (payload) => ({
  type: "HIGHLIGHT_SET",
  payload: {
    ...payload,
  },
});

const findMatchingWordsForWildcard = ({
  resultString,
  word,
  result,
  isExact,
}) => {
  // Escape all regex special characters except for * and ?
  const escapedWildcard = word.replace(/[.+^${}()|[\]\\]/g, "\\$&");

  // Replace * with regex pattern to match zero or more characters
  // Replace ? with a pattern to match zero or one character
  const regexPattern = escapedWildcard
    .replace(/\*/g, "\\w*") // matches zero or more word characters
    .replace(/\?/g, "\\w?"); // matches zero or one word character

  // Build the regex pattern based on whether an exact match is needed
  const regex =
    isExact || escapedWildcard.match(/(\*|\?)/g)
      ? new RegExp(`\\b${regexPattern}\\b`, "gi") // exact word match
      : new RegExp(`${regexPattern}`, "gi"); // partial word match

  // Extract matching words
  let matchingWords = resultString.match(regex) || [];
  result.push(...matchingWords);
};

export const findMatchingWords = (result, queryWords, isExact = false) => {
  const words = [];
  localStorage.removeItem("queryWords");
  localStorage.setItem("queryWords", JSON.stringify(queryWords));

  queryWords.forEach((wildcard) => {
    if (wildcard.match(/(\*|\?)/g)) {
      findMatchingWordsForWildcard({
        resultString: JSON.stringify(result),
        word: wildcard,
        result: words,
        isExact,
      });
    } else {
      words.push(wildcard);
    }
  });

  // const queryWordsLower = queryWords.map((i) => i.toLowerCase());
  let filteredWord = words.map((i) => i.toLowerCase());

  filteredWord = [...new Set(filteredWord)];

  return new Promise((resolve) => {
    filteredWord = filteredWord.map((i) => {
      return {
        word: i,
        isQueryWord: true,
        color: "#fffb00",
      };
    });
    resolve(filteredWord);
  });
};

export const findMatchingWordsFromResults = (
  result,
  queryWords,
  isExact = false
) => {
  const words = [];

  queryWords.forEach((wildcard) => {
    findMatchingWordsForWildcard({
      resultString: JSON.stringify(result),
      word: wildcard,
      result: words,
      isExact,
    });
  });
  let filteredWord = [];
  words.map(
    (i) =>
      !filteredWord.includes(i.toLowerCase()) &&
      filteredWord.push(i.toLowerCase())
  );
  return filteredWord;
};
