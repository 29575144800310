import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RwireReportString } from "../../components/report";
import {
  setReportString,
  getReportProjectString,
  updateReportProjectStringRow,
  addString,
  deleteRow,
  updateReportProjectStringSortOrder,
} from "../../action/report/report-string";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import StringsSection from "../../components/report-new/stringsSection";
import { setProjectReport } from "../../action/report/report-project";

const Container = (props) => {
  const { onGetReportProjectString } = props;
  const navigate = useNavigate();
  const projectId = sessionStorage.getItem("projectId");
  useEffect(() => {
    if (projectId) {
      onGetReportProjectString({ projectId });
    } else {
      navigate("/en/existing-project");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return <StringsSection {...props} />;
};

const mapStateToProps = ({ reportString, project: { selectedStrings } }) => ({
  ...reportString,
  selectedStrings,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetReportString: setReportString,
      onGetReportProjectString: getReportProjectString,
      onUpdateReportProjectStringRow: updateReportProjectStringRow,
      onAddRow: addString,
      onUpdateReportProjectStringSortOrder: updateReportProjectStringSortOrder,
      onDeleteRow: deleteRow,
      onSetProjectReport: setProjectReport,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
