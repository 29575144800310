/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import RWirePatentSideBar from "../../container/rwire-patent-sidebar";
import RWireTable from "../../container/result-table";
import RWirePatentsSearch from "../../components/common/patents-result-common/rwire-patents-search-input";
import RWirePatentsCount from "../../components/common/patents-result-common/rwire-patent-count";
import RWirePatentFilterDetails from "../../components/common/patents-result-common/rwire-patents-filter-details";
import { Modal } from "rsuite";
import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine";
import RwireExport from "../../container/export";
import IncrementalQuery from "../../container/incremental-query";
import SearchResultsBaseNote from "./results-base";
import "./style.scss";
import RwireImageCarousel from "./rwire-image-carousel";
import RwireLoader from "../../container/rwire-loader";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";
import RWireButton from "../../components/rwire-ui/rwire-button/rwire-button";

function RWireSearchResult(props) {
  const {
    totalRecordsCount,
    onSortDropdown,
    onPerformSearch,
    totalApplicationCount,
    onFetchIncrementalQueryDetails,
    historyData,
    lastQueryString,
    displayQueryIncremental,
    onSetIncremental,
    searchQuery,
    aggregations,
    onSetApp,
    collapsableFields,
    onSetResultTable,
    onEditModalClick,
    isActiveEditModal,
    onGetFiltersOptions,
    isLoadingTable,
    isLoadingResult,
    showFilters,
  } = props;
  const [isIncremental, setIncremental] = useState(false);
  const [hideQueryToggle, setHideQueryToggle] = useState(true);
  const [isArrow, setArrow] = useState(false);
  const [exportToggle, setExportToggle] = useState(false);
  const [isIncrementalModalOpen, setIncrementalModal] = useState(false);
  const [isImageCarouselModalOpen, setIsImageCarouselModalOpen] =
    useState(false);
  const [imagePath, setImagePath] = useState("");

  const handleGetLastQuery = () => {
    if (isIncrementalModalOpen) {
      handleOpenModal();
    } else {
      setIncremental(!isIncremental);
      onFetchIncrementalQueryDetails(true);
    }
  };

  const handleHideQuery = () => {
    setHideQueryToggle(!hideQueryToggle);
    setArrow((current) => !current);
  };

  const { t } = useTranslation();

  const handleCloseFilter = () => {
    props.onSetFilter({ openedFilterName: "" });
  };

  const handleOpenModal = () => {
    const selectedId =
      displayQueryIncremental && displayQueryIncremental.map((i) => i.id);
    onSetIncremental({
      selectedRows: [...selectedId],
      modalQuery: displayQueryIncremental,
    });
    if (isIncrementalModalOpen) {
      setIncrementalModal(false);
    } else {
      onFetchIncrementalQueryDetails(false);
      setIncrementalModal(true);
    }
  };
  return (
    <div className="container-fluid patent-result-main">
      <RwireLoader />
      {exportToggle && (
        <Modal
          className="modalexportfilter bd-example-modal-lg"
          backdrop="true"
          keyboard={false}
          open={true}
          onClose={() => {
            setExportToggle(false);
          }}>
          <RwireExport
            {...props}
            setExportToggle={setExportToggle}
            exportToggle={exportToggle}
          />
        </Modal>
      )}

      {isIncrementalModalOpen && (
        <Modal
          className="modal-class incremental-modal"
          backdrop="true"
          keyboard={false}
          open={true}
          onClose={handleOpenModal}>
          <IncrementalQuery
            data={historyData}
            onCloseModal={setIncrementalModal}
          />
        </Modal>
      )}

      {isImageCarouselModalOpen && (
        <Modal
          className="modalImageCarousel"
          open={true}
          static="static"
          onClose={() =>
            setIsImageCarouselModalOpen(!isImageCarouselModalOpen)
          }>
          <RwireImageCarousel
            isImageCarouselModalOpen={isImageCarouselModalOpen}
            setIsImageCarouselModalOpen={setIsImageCarouselModalOpen}
            imagePath={imagePath}
            setImagePath={setImagePath}
          />
        </Modal>
      )}

      <div className="patent-flex-design">
        <div
          className={`flex-shrink-0 side-menu-width ${
            showFilters ? "show" : "hide"
          }`}>
          <RWirePatentSideBar />
        </div>
        <div
          className={`patent-right-side ${showFilters ? "show" : "hide"}`}
          onClick={handleCloseFilter}>
          <div className="header-patent-main">
            <Trans>
              <div className={`header-patent-result `}>
                <div className="nav-details-patent">
                  Search &gt; {t("fielded")} &gt; {t("result")}
                </div>
                <div className="tab-patents hide-query-box">
                  <RWireButton
                    onClick={handleGetLastQuery}
                    buttonCName={`pr-1
                      ${isIncrementalModalOpen ? "bg-toggle" : ""}
                      ${!searchQuery ? "disabled-btn" : ""}
                      ${
                        !isArrow
                          ? isIncremental
                            ? ""
                            : "result-btn-border-bottom"
                          : "result-btn-border-bottom"
                      }
                    `}
                    buttonImg={RWIRE_IMAGES.RwirePlus}
                    name={t("Incremental")}
                  />
                  <button
                    className={`
                      ${
                        isActiveEditModal
                          ? "bg-toggle"
                          : "result-btn-border-bottom"
                      }
                      ${!searchQuery ? "disabled-btn" : ""}
                    `}
                    onClick={onEditModalClick}>
                    {t("edit_fielded")}
                    <ArrowDownLineIcon
                      className={
                        isActiveEditModal
                          ? "hide-query-arrow-position"
                          : "hide-query-arrow"
                      }
                    />
                  </button>
                  <button
                    className={`${isArrow ? "box-close  " : "increment-box"}
                     ${
                       !isArrow
                         ? !isIncremental
                           ? ""
                           : "result-btn-border-bottom"
                         : "result-btn-border-bottom"
                     }`}
                    onClick={handleHideQuery}>
                    {!isArrow ? t("hide_query") : "Show Query"}
                    <ArrowDownLineIcon
                      className={
                        isArrow
                          ? "hide-query-arrow"
                          : " hide-query-arrow-position"
                      }
                    />
                  </button>
                </div>
              </div>
            </Trans>
          </div>
          {hideQueryToggle && (
            <RWirePatentsSearch
              {...props}
              isIncremental={isIncremental}
              onSetIncrementalPart={setIncremental}
              historyData={historyData}
              onOpenModal={handleOpenModal}
              lastQueryString={lastQueryString}
            />
          )}
          <SearchResultsBaseNote />
          <RWirePatentsCount
            totalRecordsCount={totalRecordsCount}
            totalApplicationCount={totalApplicationCount}
            aggregations={aggregations}
            onSetApp={onSetApp}
            onPerformSearch={onPerformSearch}
            collapsableFields={collapsableFields}
            onSetResultTable={onSetResultTable}
            onGetFiltersOptions={onGetFiltersOptions}
            {...props}
          />
          {!(
            !isLoadingResult &&
            !isLoadingTable &&
            totalRecordsCount === 0
          ) && (
            <>
              <RWirePatentFilterDetails
                onSortDropdown={onSortDropdown}
                onPerformSearch={onPerformSearch}
                isLoadingResult={isLoadingResult}
                {...props}
              />
            </>
          )}
          <div className="patent-result-table">
            <Trans>
              <RWireTable
                isArrow={isArrow}
                exportToggle={exportToggle}
                style={{
                  height: "400px",
                }}
                setExportToggle={setExportToggle}
                isImageCarouselModalOpen={isImageCarouselModalOpen}
                setIsImageCarouselModalOpen={setIsImageCarouselModalOpen}
                imagePath={imagePath}
                setImagePath={setImagePath}
                showFilters={showFilters}
              />
            </Trans>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RWireSearchResult;
