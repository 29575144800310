/* eslint-disable no-console */
import { fetch } from "../../service/fetch";

export const setReportAssignee = (payload) => ({
  type: "SET_ASSIGNEE",
  payload: {
    ...payload,
  },
});

export const getReportProjectAssignees = () => async (dispatch) => {
  try {
    var response = {};
    const id = sessionStorage.getItem("projectId");
    response = await fetch(
      {
        url: `/api/project-assignee/project/${id}`,
      },
      "GET"
    );
    dispatch(
      setReportAssignee({
        reportTableData: response.result,
        undoStack: [],
        redoStack: [],
      })
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const updateReportProjectAssigneesRow =
  ({ id, body }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-assignee/${id}`,
          body,
        },
        "PUT"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const updateReportProjectAssigneesSortOrder =
  (body) => async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-assignee/sort-order`,
          body,
        },
        "POST"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const addRowToAssigneeReportProject = (body) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-assignee`,
        body,
      },
      "POST"
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const deleteRowFromAssigneeReportProject = (id) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-assignee/${id}`,
      },
      "DELETE"
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};
export const addAssignee =
  ({ projectId, body, patentNumber }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-assignee`,
          body: {
            data: body,
            projectId,
            patentNumber,
          },
        },
        "POST"
      );
    } catch (error) {
      console.log("error", error);
    }
  };

export const removeAssignee =
  ({ projectId, body, patentNumber }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-assignee/delete-assignee`,
          body: {
            data: body,
            projectId,
            patentNumber,
          },
        },
        "POST"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };
