import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Block from "./block";

const Modal = ({
  isOpen,
  onClose,
  children,
  className = "",
  isStatic = true,
  overlayClass = "",
}) => {
  const handleOverlayClick = (e) => {
    if (e.target.classList[0]?.includes("rwire-custom-modal-overlay")) {
      if (onClose) {
        onClose();
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup: Reset body overflow when component unmounts or modal closes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <Block
      className={`rwire-custom-modal-overlay ${overlayClass}`}
      onClick={handleOverlayClick}
    >
      <Block className={`rwire-custom-modal ${className}`}>{children}</Block>
    </Block>,
    document.getElementById("rwire-custom-modal-root")
  );
};

export default Modal;
