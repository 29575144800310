import { useEffect } from "react";
import { useStore } from "react-redux";
import { ACCESS_COMMENTOR } from "../../common/user-roles";
import ChartBlock from "../report/rwire-report-dashboard/chart-block";
import KeyFeaturesCount from "../report/rwire-report-dashboard/key-features-count";

const GraphsSection = (props) => {
  const {
    string,
    keyFeaturesList = [],
    onGetReportProjectNotes,
    keyFeature,
    projectName,
  } = props;

  const projectId = sessionStorage.getItem("projectId");
  useEffect(() => {
    onGetReportProjectNotes();
  }, [onGetReportProjectNotes, projectId]);

  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();

  return (
    <div className="mt-2 py-3 px-4">
      <div className="px-4 pt-4 pb-0 graphs-bg">
        {userProjectAccess !== ACCESS_COMMENTOR && (
          <div className="dashboard-content">
            <div
              className={`d-flex content_wrapper justify-content-${
                keyFeaturesList.filter((i) => i.body).length === 0
                  ? "center"
                  : "between"
              }`}
            >
              {string?.count > 0 &&
              keyFeaturesList.filter((i) => i.body).length !== 0 ? (
                <ChartBlock {...props} />
              ) : null}
              {keyFeaturesList.length > 0 ? (
                <KeyFeaturesCount
                  keyFeaturesList={keyFeaturesList}
                  onGetReportProjectNotes={onGetReportProjectNotes}
                  keyFeature={keyFeature}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GraphsSection;
