export const otherHighlightWordsColors = [
  "#fffb00",
  "#BBE9DB",
  "#D0CECE",
  "#DAFF8A",
  "#DFB7A1",
  "#AECCC6",
  "#4EAEA5",
  "#83D9EF",
  "#FEFF94",
  "#DA77FB",
  "#CDAA2F",
  "#BCFFF2",
  "#FFC0D0",
  "#FFE0E0",
  "#EEFCA9",
  "#B7E576",
  "#ACE6F6",
  "#62D2A2",
  "#DFBAF7",
  "#FA7F7F",
  "#E0FFCD",
  "#FFEBBB",
  "#FFCAB0",
  "#F4CB81",
  "#A0E4B0",
  "#83CC61",
  "#FFE6CC",
  "#F1F592",
  "#9DF3C4",
  "#A6E3E9",
  "#FFE6EB",
  "#769FCD",
  "#F8FBA2",
  "#ECFFC1",
  "#C7D154",
  "#A7DEB9",
  "#F8F1E5",
  "#E4D183",
  "#FFA63E",
  "#D6E6F2",
  "#FFE3B9",
  "#C2FCD9",
  "#92CCE1",
  "#2794EB",
  "#81DDFF",
  "#B5B2FF",
  "#FFE1A1",
  "#FCFFCC",
  "#CBF1F5",
  "#D3E785",
  "#B0DF7E",
  "#9FA4FA",
  "#EFDFBF",
  "#DDE7F2",
  "#00DB47",
  "#D8C4C4",
  "#60A9A6",
  "#B8FFD0",
  "#B9D7EA",
  "#ECFFC1",
  "#EB8AE6",
  "#B3F7F6",
  "#FDFFCD",
  "#7FDFD4",
  "#FBAC91",
  "#5C7EFA",
  "#D7FBE8",
  "#B4DA85",
  "#F38181",
  "#F5FAC8",
  "#8198F7",
  "#F0ECE2",
  "#FFD782",
  "#C7B198",
  "#DEFCFC",
  "#CAABD8",
  "#FFCCCC",
  "#C2FAF1",
  "#4993FA",
  "#A8D8EA",
  "#FFFFD2",
  "#F6F39F",
  "#F392F2",
  "#B062EA",
  "#E5F9BD",
  "#BEFF3B",
  "#CBBCF6",
  "#EBAF81",
  "#FFDEDE",
  "#5BBFE0",
  "#118DF0",
  "#DFD3C3",
  "#46C3A7",
  "#FCBAD3",
  "#FF9CFE",
  "#F4FF61",
  "#FFA4A4",
  "#D4CEB0",
  "#5C7EFA",
  "#C1F880",
  "#FF6BD6",
];

export const queryHighlightWordsColors = otherHighlightWordsColors;

export const dashboardKeyFeaturesColor = [
  "#D7FCF1",
  "#EEEBEB",
  "#F2FFD6",
  "#D3E7CE",
  "#BCFFF2",
  "#FFDCE5",
  "#D8EBBE",
  "#F0DBFE",
  "#E0FFCD",
  "#FEEDC5",
  "#C8EED1",
  "#FFE6EB",
  "#D5D9FB",
  "#ECFFC1",
  "#FFD9F6",
  "#DEFAFD",
  "#C5FCF2",
  "#E1DDFA",
  "#EDEEEE",
  "#D6F9E5",
  "#E4CFCF",
  "#F1FFE8",
  "#D0E9D5",
  "#B7FFEA",
  "#FFE6E0",
  "#D6F1B7",
  "#F1E6FF",
  "#D0FFD5",
  "#FEF1D7",
  "#C6E6C7",
  "#FFF1F4",
  "#D1D8FB",
  "#EBFFC5",
  "#FBC6E4",
  "#C1F6FC",
  "#C9FEFB",
  "#E6F2E5",
  "#F0E4F5",
  "#F6FEED",
  "#C7F2E6",
  "#E0D8E1",
  "#E7FFDA",
  "#F1E2D6",
  "#D9FFE1",
  "#D6C4F2",
  "#F2FFF1",
  "#E3E8FC",
  "#F4D9F6",
  "#C3E4D6",
  "#F0FEF7",
  "#E9C2E8",
  "#D6E6F0",
  "#F7E4D5",
  "#B3FFD7",
  "#E6FFD9",
  "#FAE2D7",
  "#C4F5F2",
  "#E5D1D9",
  "#C9E5C3",
  "#E4F3E4",
  "#F2D8FF",
  "#D7F5F0",
  "#F0B8F8",
  "#D7E1E5",
  "#FEF3D1",
  "#E2FFEB",
  "#F4D9D7",
  "#C1D8E4",
  "#E5FFBF",
  "#F9D1E8",
  "#E8F8C4",
  "#D2E6F5",
  "#F8E0E4",
  "#D7FFF5",
  "#F3E6E9",
  "#C6E3D5",
  "#F0D3FF",
  "#D0F6E9",
  "#E1D1C9",
  "#F3FFF3",
  "#E4C6ED",
  "#D1FFD1",
  "#F0D7C5",
  "#C9E8D7",
  "#E3D4F0",
  "#F7FFF6",
  "#E1E8E3",
  "#D7C9E0",
  "#F2D3D9",
  "#C5FFE2",
  "#F5E1B8",
  "#D4E0FF",
  "#E7F1D2",
  "#F2E2F1",
  "#D9FFEE",
  "#F9E8C4",
  "#C8E8FC",
  "#E7D6D8",
  "#F0F1E4",
  "#C6D4FF",
  "#F6FFE6",
  "#E0C2E7",
  "#D4FFE7",
  "#F4D4FF",
  "#C7D3D5",
  "#E8F9C3",
  "#F1E0D1",
  "#D2C4F7",
  "#E4F5D5",
  "#F5D8FF",
  "#C8E7E5",
  "#E1FFD3",
  "#F3F2E4",
  "#D1E7E6",
  "#F6B0C4",
  "#E0E2FF",
  "#C2FFD3",
  "#F1D4E6",
  "#D9E1F0",
];
