import Select from "react-select";
import { useSelector } from "react-redux";

const TopNumberFilter = (props) => {
  const { onHandleTopResultSet, topNumberSize = "10", onSetChart } = props;

  const isDarkMode = useSelector((state) => state.app.isDarkMode);

  const handleTopSelect = (value) => {
    onSetChart({ listMerge: [] });

    onHandleTopResultSet("", value.value);
  };

  const optionStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
      cursor: "pointer",
      color: isDarkMode ? "white" : "black",
      backgroundColor: state.isFocused
        ? isDarkMode
          ? "#222"
          : "#f0f0f0"
        : "inherit",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      minHeight: 30,
      height: 30,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      cursor: "pointer",
      minHeight: 30,
      height: 30,
    }),
  };

  return (
    <Select
      onChange={handleTopSelect}
      options={[
        { value: "10", label: "Top 10" },
        { value: "20", label: "Top 20" },
        { value: "25", label: "Top 25" },
      ]}
      defaultValue={{ value: topNumberSize, label: `Top ${topNumberSize}` }}
      styles={optionStyles}
      isSearchable={false}
    />
  );
};

export default TopNumberFilter;
