import React from "react";
import "./style.scss";
import Facebook from "../../assets/images/faq_facebook.png"
import Twitter from "../../assets/images/faq_twitter.png"
import Linkedin from "../../assets/images/faq_linkedin.png"
import Youtube from "../../assets/images/faq_youtube.png"
import { useState } from "react";
const ContactUs = () => {
  const [sendEmail, setSendEmail] = useState(false);
  const handleSubmit = () => {
    setSendEmail(true)
    setTimeout(() => {
      setSendEmail(false)
    }, 3000)
  }

  return (<div className="last_section d-flex">
    <div className="last_left_section py-4 px-4">
      <p className="left_section_heading">Contact Us</p>
      <p className="left_section_para">
        Have questions or need assistance? Our team is here to help. Reach out to us at  <b>[query@researchwire.in]</b>
      </p>
      <p className="left_section_second_para">
        Connect with us on social media for the latest
        updates and insights
        <span className="d-flex flex-row justify-content-center icons-container">
          <a href="https://www.linkedin.com/company/researchwire-knowledge-solutions/"><img className="social-icons ml-2" src={Linkedin} alt="linkedin" /></a>
          <a href="https://x.com/researchwire"><img className="social-icons ml-2" src={Twitter} alt="twitter" /></a>
          <a href="https://www.youtube.com/@researchwire5774"><img className="social-icons ml-2" src={Youtube} alt="youtube" /></a>
          <a href="https://www.facebook.com/researchwireknowledgesolutions/"><img className="social-icons ml-2" src={Facebook} alt="facebook" /></a>
        </span>
      </p>
    </div>

    <div className="last_right_section px-5 py-5 my-3 mr-4">
      <p className="right_section_heading">Do You Have Any Questions?</p>
      <p className="right_section_heading mb-3">Get Help From Us</p>
      <div className="d-flex justify-content-center align-items-center mt-2">
        <input
          className="last_section_input px-2 py-2 mr-3"
          placeholder="Enter Your Email Address"
        />
        <div className="last_section_submit">
          <p className="last_btn_text">Submit</p>
        </div>
      </div>
      {
        sendEmail === true && (
          <div className="d-flex flex-row align-items-start mx-5">
            <p className="email_success_text">Thank You! Email has been Sent Successfully.</p>
          </div>
        )
      }
    </div>
  </div>)
};

export default ContactUs;
