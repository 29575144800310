/* eslint-disable no-console */
import { fetch } from "../../service/fetch";
import { setProjectReport } from "./report-project";
import { setApp } from "../app";

export const addUserToProject = (body) => async (dispatch) => {
  try {
    var response = {};
    response = await fetch(
      {
        url: `/api/project-users`,
        body,
      },
      "POST"
    );
    return response.createdIds;
  } catch (error) {
    console.log("add user to project error", error);
  }
};
export const sendInvitationToCollaborator = (body) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-users/collaborator`,
        body,
      },
      "POST"
    );
  } catch (error) {
    console.log("add user to project error", error);
    throw error;
  }
};

export const getProjectAccess = (response, currentUserId) => {
  const currentUserDetails = response.filter(
    (element) => element.userId === currentUserId
  );
  return currentUserDetails[0].access;
};

export const getAllProjectUsers = (id) => async (dispatch, getState) => {
  try {
    var response = {};
    var currentUserId;
    response = await fetch(
      {
        url: `/api/user/profile`,
      },
      "GET"
    );
    currentUserId = response.id;
    response = await fetch(
      {
        url: `/api/project-users/${id}/users`,
      },
      "GET"
    );
    const userAccess = getProjectAccess(response, currentUserId);
    dispatch(
      setProjectReport({
        projectUsers: response,
      })
    );
    dispatch(setApp({ userProjectAccess: userAccess }));
    response = await fetch(
      {
        url: `/api/project-users/${id}/role`,
      },
      "GET"
    );

    sessionStorage.setItem("userRole", response.role);
  } catch (error) {
    throw error;
  }
};

export const updateRoleOfUserInProject = (id, body) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-users/${id}`,
        body,
      },
      "PUT"
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const removeAccessOfUserFromProject = (id) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-users/${id}`,
      },
      "DELETE"
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const getAllExistingOrganizationalUsers =
  (id) => async (dispatch, getState) => {
    try {
      var response = {};
      response = await fetch(
        {
          url: `/api/user/users`,
        },
        "GET"
      );

      dispatch(
        setProjectReport({
          existingOrganizationalUsers: response.result,
        })
      );
    } catch (error) {
      throw error;
    }
  };

export const getUserDetailsUsingId = (id) => async (dispatch, getState) => {
  try {
    var response = {};
    response = await fetch(
      {
        url: `/api/user/${id}/details`,
      },
      "GET"
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const createAddUserRequests = (body) => async (dispatch, getState) => {
  try {
    await fetch(
      {
        url: `/api/user-request`,
        body,
      },
      "POST"
    );
  } catch (error) {
    throw error;
  }
};
