import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  addRowToInventorReportProject,
  deleteRowFromInventorReportProject,
  getReportProjectInventors,
  setReportInventor,
  updateReportProjectInventorsRow,
  updateReportProjectInventorsSortOrder,
} from "../../action/report/report-inventor";
import { useEffect } from "react";
import Inventors from "../../components/report-new/inventors";

const Container = (props) => {
  const projectId = sessionStorage.getItem("projectId");
  const { onGetReportProjectInventors } = props;
  useEffect(() => {
    onGetReportProjectInventors();
  }, [projectId, onGetReportProjectInventors]);

  return <Inventors {...props} />;
};

const mapStateToProps = ({ reportInventor }) => ({
  ...reportInventor,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetReportInventor: setReportInventor,
      onUpdateInventorSortOrder: updateReportProjectInventorsSortOrder,
      onGetReportProjectInventors: getReportProjectInventors,
      onUpdateReportProjectInventorRow: updateReportProjectInventorsRow,
      onAddRowToreportProject: addRowToInventorReportProject,
      onDeleteRowFromReportProject: deleteRowFromInventorReportProject,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
