import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  reportClasessSearch,
  setReportClasess,
  getPagination,
  setPage,
  addRowToClassesReportProject,
  getReportProjectClasses,
  updateReportProjectClassesRow,
} from "../../action/report/report-clasess";
import ClassesGeneratorModal from "../../components/report-new/classes-generator-modal";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { isObjectBlank } from "../../action/result-table";

const Container = (props) => {
  const navigate = useNavigate();
  const _id = sessionStorage.getItem("projectId");
  useEffect(() => {
    if (_id) {
      if (isObjectBlank(props.reportTableData)) {
        props.onGetReportProjectClasses();
      }
    } else {
      navigate("/en/existing-project");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  // return <ReportClassesModal {...props} />;
  return <ClassesGeneratorModal {...props} />;
};

const mapStateToProps = ({ reportClasses }) => ({
  ...reportClasses,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetClassesModal: setReportClasess,
      onClassesSearch: reportClasessSearch,
      onGetPagination: getPagination,
      onSetPage: setPage,
      onSetSelectedRows: setReportClasess,
      onSetItemPerPage: setReportClasess,
      onSetClassesSelector: setReportClasess,
      onAddRowToreportProject: addRowToClassesReportProject,
      onGetReportProjectClasses: getReportProjectClasses,
      onUpdateReportProjectClassesRow: updateReportProjectClassesRow,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
