import HighLighter from "./check-word-for-highlight";

const Highlight = (props) => {
  const {
    children,
    isExact,
    highlightedWords,
    component: Component = "div",
    queryRegex,
  } = props;
  const value = HighLighter(children, highlightedWords, isExact, queryRegex);

  return (
    <Component
      dangerouslySetInnerHTML={{
        __html: typeof children === "number" ? children : value,
      }}></Component>
  );
};

export default Highlight;
