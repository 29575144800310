import React, { useState, useEffect } from "react";
import TableHeader from "./table-header";
import HistoryRow from "./history-row";
import { uuidv4 } from "../../action/app";
import "./styles.scss";
import Pagination from "../rwire-ui/rwire-pagination";
import ActionButtons from "./action-buttons";

const History = (props) => {
  const {
    data,
    totalRecordsCount,
    itemsPerPage,
    page,
    onGetPagination,
    onSetItemPerPage,
    onSetPage,
    onDeleteHistory,
    onFetchHistory,
    onBookmarkHistory,
    selectedRows,
    onSetSelectedRows,
    onSetExportSelected,
    onSetApp,
    selectedItemList,
    onPerformSearch,
    onExportHistory,
    onUpdateQueryCount,
    setSelectedField,
    selectedRowsForCombine,
    onSetSelectedCombineQuery,
    onSetSelectedCombineQueryId,
  } = props;

  const [editQuery, setEditQuery] = useState("");
  const [sortedData, setSortedData] = useState(data);
  const [sortOrderNumbers, setSortOrderNumbers] = useState("desc");
  const [sortOrderDateTime, setSortOrderDateTime] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);

  useEffect(() => {
    setSortedData(data);
    // Apply sorting when data changes
    applySorting(data, sortedColumn, sortOrderNumbers, sortOrderDateTime);
  }, [data, sortedColumn, sortOrderNumbers, sortOrderDateTime]);

  const applySorting = (data, column, orderNumbers, orderDateTime) => {
    const sortedData = [...data];

    if (column === "numbers") {
      if (orderNumbers === "asc") {
        sortedData.sort((a, b) => a.numberOfHits - b.numberOfHits);
      } else {
        sortedData.sort((a, b) => b.numberOfHits - a.numberOfHits);
      }
    } else if (column === "date-time") {
      if (orderDateTime === "asc") {
        sortedData.sort((a, b) => a.createdAt - b.createdAt);
      } else {
        sortedData.sort((a, b) => b.createdAt - a.createdAt);
      }
    }

    setSortedData(sortedData);
  };

  const handleSortData = (
    sortedData,
    sortedColumn,
    orderNumbers,
    orderDateTime
  ) => {
    setSortedColumn(sortedColumn);
    setSortOrderNumbers(orderNumbers);
    setSortOrderDateTime(orderDateTime);
    applySorting(sortedData, sortedColumn, orderNumbers, orderDateTime);
  };

  const handleChange = (p) => {
    onSetPage(p);
    onFetchHistory();
  };

  return (
    <div>
      <div className={`table-wrapper normal-scroll-bar`}>
        <div className="table-inner">
          <TableHeader
            selectedRows={selectedRows}
            onSetSelectedRows={onSetSelectedRows}
            data={sortedData}
            onSetExportSelected={onSetExportSelected}
            selectedItemList={selectedItemList}
            onSetItemPerPage={onSetItemPerPage}
            selectedRowsForCombine={selectedRowsForCombine}
            onSortData={handleSortData}
          />

          <div className="body_wrapper">
            {sortedData.map((item) => (
              <HistoryRow
                key={uuidv4()}
                isBookmark={item.isBookmark}
                id={item.id}
                numberOfHits={item.numberOfHits}
                userSearchNumber={item.userSearchNumber}
                query={item.query}
                createdAt={item.createdAt}
                updatedAt={item.updatedAt}
                onDeleteHistory={onDeleteHistory}
                onFetchHistory={onFetchHistory}
                selectedRows={selectedRows}
                onBookmarkHistory={onBookmarkHistory}
                onSetSelectedRows={onSetSelectedRows}
                onSetExportSelected={onSetExportSelected}
                selectedItemList={selectedItemList}
                onSetApp={onSetApp}
                onPerformSearch={onPerformSearch}
                editQuery={editQuery}
                setEditQuery={setEditQuery}
                historyTableRow={item}
                // setEditQueryId={setEditQueryId}
                {...props}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="pagination-action-buttons">
        <div className="pagination-block">
          <div className="container">
            <Pagination
              {...props}
              onSetItemPerPage={onSetItemPerPage}
              onGetPagination={onGetPagination}
              total={totalRecordsCount ? totalRecordsCount : 0}
              itemsPerPage={itemsPerPage}
              page={page}
              onSetPage={handleChange}
              isResultFetch={false}
            />
          </div>
        </div>
        <div className="action-buttons-block">
          <div className="container">
            <ActionButtons
              selectedRows={selectedRows}
              onFetchHistory={onFetchHistory}
              onUpdateQueryCount={onUpdateQueryCount}
              data={sortedData}
              onExportHistory={onExportHistory}
              setSelectedField={setSelectedField}
              onSetSelectedCombineQuery={onSetSelectedCombineQuery}
              onSetSelectedCombineQueryId={onSetSelectedCombineQueryId}
              selectedItemList={selectedItemList}
            />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default History;
