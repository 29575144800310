import React, { useState, useEffect } from "react";
// import { Modal } from "../../../ui-components";
import { Button } from "react-bootstrap";
import { Modal } from "../../ui-components";
import { updateClassTypeTableData } from "./utils";

export default function ClassesGeneratorModal(props) {
  const {
    isOpen,
    onClose,
    onClassesSearch,
    classesType,
    onSetClassesSelector,
    formData,
    onSetClassesModal,
    clasessData = [],
    onAddRowToreportProject,
    onGetReportProjectClasses,
    selectedRowsWithCodeAndDefination,
    selectedRows = [],
    onUpdateReportProjectClassesRow,
    reportTableData,
  } = props;

  const [isSearching, setIsSearching] = useState(false);
  const [searchAttempted, setSearchAttempted] = useState(false);

  useEffect(() => {
    onSetClassesSelector({
      classesType: "cpc",
    });
  }, [onSetClassesSelector]);

  const handleSetValue = (event) => {
    onSetClassesModal({
      formData: { ...formData, [event.target.name]: event.target.value },
    });
  };

  const handleChangeType = (e) => {
    const selectedType = e.target.value;

    onSetClassesModal({
      formData: { ...formData, searchType: selectedType },
    });

    onSetClassesSelector({
      classesType: selectedType.toLowerCase(),
      page: 1,
      selectedRows: [],
      selectedRowsWithCodeAndDefination: [],
    });
    if (formData.classes || formData.keywords) {
      setTimeout(() => {
        onClassesSearch();
      }, 200);
    }
  };

  const handleKeyUp = async (e) => {
    if (e.keyCode === 13) {
      setIsSearching(true);
      await onClassesSearch();
      setIsSearching(false);
    }
  };
  const handleSearchClick = async () => {
    setIsSearching(true); // Set isSearching to true when search button is clicked
    await onClassesSearch(); // Call onClassesSearch
    setIsSearching(false);
  };

  const handleCheckbox = (e, _source) => {
    const { code: selectedCode, definition: selectedDefinition } = _source;

    const newSelectedId = e.target.checked
      ? [...selectedRows, selectedCode]
      : selectedRows.filter((item) => item !== selectedCode);

    const newSelectedDetails = e.target.checked
      ? [
          ...selectedRowsWithCodeAndDefination,
          { code: selectedCode, definition: selectedDefinition },
        ]
      : selectedRowsWithCodeAndDefination.filter(
          (item) => item.code !== selectedCode
        );

    onSetClassesSelector({
      selectedRows: newSelectedId,
      selectedRowsWithCodeAndDefination: newSelectedDetails,
    });
  };

  const handleApply = async () => {
    const updatedTableData = updateClassTypeTableData(
      { ...reportTableData },
      [...selectedRowsWithCodeAndDefination],
      classesType
    );

    // Update the existing items in the backend
    await Promise.all(
      updatedTableData
        .filter((row) => row.id)
        .map(async (row) => {
          await onUpdateReportProjectClassesRow({
            id: row.id,
            body: {
              classes: row.classes,
              body: row.body,
            },
          });
        })
    );

    // Add new rows for newly created items
    const newKeywords = updatedTableData.filter((row) => !row.id);
    if (newKeywords.length > 0) {
      const projectId = parseInt(sessionStorage.getItem("projectId"));
      await onAddRowToreportProject({
        type: classesType.toUpperCase(),
        data: newKeywords,
        projectId: projectId,
      });
    }
    await onGetReportProjectClasses();
    handleModalClose();
  };

  const handleModalClose = () => {
    onSetClassesModal({
      formData: { classes: "", keywords: "", searchType: "cpc" }, // Resetting formData here
    });
    onSetClassesSelector({
      selectedRows: [],
      selectedRowsWithCodeAndDefination: [],
      clasessData: [],
    });
    onClose(); // Close the modal
  };

  return (
    <Modal
      isOpen={isOpen}
      isStatic={false}
      overlayClass="project-creation-modal"
      className="project-creation-modal-body classes-generator"
    >
      <div className="modal-content border-0 fs-16">
        <div className="modal-header bg-white border-0 px-4">
          <h5 className="modal-title fs-16 primary-heading-text">
            Please enter class or keyword to generate classes:
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleModalClose}
          ></button>
        </div>
        <div className="py-0 px-4">
          <form>
            <div className="modal-body d-flex px-3 justify-content-between">
              <div className="col-6 fs-16">
                <div className="row g-3 align-items-center">
                  <div className="col-md-2 ps-0">
                    <label
                      htmlFor="classInput"
                      className="col-form-label fw-bold"
                    >
                      Class
                    </label>
                  </div>
                  <div className="col">
                    <input
                      name="classes"
                      type="text"
                      id="classInput"
                      className="form-control fs-16"
                      placeholder="Search by Class"
                      onChange={(event) => handleSetValue(event)}
                      value={formData.classes}
                      onKeyUp={handleKeyUp}
                    />
                  </div>
                </div>
                <div className="row g-3 align-items-center mt-2">
                  <div className="col-md-2 ps-0">
                    <label
                      htmlFor="keywordInput"
                      className="col-form-label fw-bold"
                    >
                      Keyword
                    </label>
                  </div>
                  <div className="col">
                    <input
                      name="keywords"
                      type="text"
                      id="keywordInput"
                      className="form-control fs-16"
                      placeholder="Search by Keyword"
                      value={formData.keywords}
                      onChange={(event) => handleSetValue(event)}
                      onKeyUp={handleKeyUp}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4 ps-5 d-flex flex-column justify-content-between ">
                {["cpc", "ipc", "us"].map((radioType, index) => (
                  <div
                    className="form-check form-check-inline cursor-pointer"
                    key={index}
                  >
                    <input
                      className="me-1 cursor-pointer"
                      type="radio"
                      name="searchType"
                      id={radioType}
                      value={radioType}
                      checked={classesType === radioType}
                      onChange={handleChangeType}
                    />
                    <label
                      className="form-check-label p-0 cursor-pointer"
                      htmlFor={radioType}
                    >
                      {radioType.toUpperCase()}
                    </label>
                  </div>
                ))}
              </div>
              <div className="col-2 mt-auto">
                <Button
                  type="button"
                  className="create-project-btn btn-with-blue-border-white-bg fs-16"
                  onClick={handleSearchClick}
                >
                  Search
                </Button>
              </div>
            </div>
          </form>
        </div>
        <div className="py-0 mt-1 px-4">
          <div className="keywords-table">
            <div className="header py-1 d-flex">
              <div className="table-checkbox"></div>
              <div className="table-class fw-bold">class</div>
              <div className="table-defination fw-bold"> Definition</div>
            </div>
            <div className="table-body">
              {isSearching ? (
                <div className=" loading-message d-flex justify-content-center align-items-center">
                  <p>Loading...</p>
                </div>
              ) : (
                <table className="table">
                  <tbody>
                    {clasessData.map((item) => (
                      <tr key={item._id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(item._source.code)}
                            defaultChecked={selectedRows.includes(
                              item._source.code
                            )}
                            value={item._source.code}
                            onChange={(e) => handleCheckbox(e, item._source)}
                          />
                        </td>
                        <td>{item._source.code}</td>
                        <td
                          style={{
                            borderBottom: "1px solid #ccc", // Bottom border for each claim
                            padding: "10px",
                          }}
                        >
                          {item._source.definition}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        <div className="modal-footer border-0">
          <button
            type="button"
            className="btn cancel-btn fs-16"
            onClick={onClose}
          >
            Cancel
          </button>
          <Button
            type="button"
            className="create-project-btn btn-with-blue-border-white-bg fs-16"
            onClick={handleApply}
          >
            Add
          </Button>
        </div>
      </div>
    </Modal>
  );
}
