import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { FaSearch, FaStar } from "react-icons/fa";
import ContentEditableInput from "./ContentEditableInput";
import { Image, SlidingSidePanel } from "../../ui-components";
import {
  MinusBlackIcon,
  PlusBlackIcon,
  RightBlackIcon,
} from "../../assets/images/report";
import ReportCollaboration from "../../container/report/report-collaboration";

const StringsSection = (props) => {
  const {
    reportTableData = [],
    onUpdateReportProjectStringRow,
    onAddRow,
    onDeleteRow,
    onGetReportProjectString,
    selectedStrings,
    onSetProjectReport,
  } = props;
  const [commentingRowID, setCommentingRowID] = useState(null);
  const handleCheckboxChange = (e, id) => {
    let strings = [...selectedStrings];
    if (e.target.checked) {
      strings.push(id);
    } else {
      strings = strings.filter((string) => string !== id);
    }
    onSetProjectReport({
      selectedStrings: strings,
    });
  };

  const handleAddRow = async (rowIndex) => {
    const _id = sessionStorage.getItem("projectId");
    await onAddRow({
      body: "",
      publicationCount: 0,
      applicationCount: 0,
      order: parseInt(`${rowIndex + 1}`),
      projectId: parseInt(_id),
    });
    await onGetReportProjectString({ projectId: _id });
  };

  const handleDeleteRow = async (id) => {
    if (reportTableData.length === 1) {
      return;
    }
    const _id = sessionStorage.getItem("projectId");
    await onDeleteRow(id);
    await onGetReportProjectString({ projectId: _id });
  };

  const handleSaveInDb = async (value, rowIndex, idx, options) => {
    if (options.isChangeText) {
      await onUpdateReportProjectStringRow({
        id: rowIndex,
        body: {
          body: value,
        },
      });
    } else if (options.isChangeDesc) {
      const pubNo = value.split("/")[0];
      const appNo = value.split("/")[1];
      await onUpdateReportProjectStringRow({
        id: rowIndex,
        body: {
          publicationCount: pubNo,
          applicationCount: appNo,
        },
      });
    }
  };
  return (
    <div className="patent-analysis-tool bg-white px-3 py-2">
      <div className="px-4  py-3 strings-section border">
        <div className="strings-table">
          <div className="table-responsive">
            <Table bordered={false}>
              <thead>
                <tr>
                  <th className="checkbox-column fs-16">
                    <FaStar className="star-icon" />
                  </th>
                  <th className="text-center fs-16 border-end-0">Strings</th>
                  <th className="text-center action-col border-end-0  border-start-0 fs-16"></th>
                  <th className="text-center action-col border-end-0 border-start-0 fs-16"></th>
                  <th className="text-center action-col  border-start-0 fs-16"></th>
                  <th className="count-column fs-16">Count</th>
                </tr>
              </thead>
              <tbody>
                {reportTableData &&
                  reportTableData.length > 0 &&
                  reportTableData.map((item, index) => (
                    <tr key={item.id}>
                      <td className="checkbox-column fs-16">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={selectedStrings.includes(item.id)}
                            onChange={(e) => handleCheckboxChange(e, item.id)}
                            id={`checkbox-${item.id}`}
                          />
                        </div>
                      </td>
                      <td className="string-column fs-16 align-middle border-end-0">
                        <ContentEditableInput
                          text={item.title}
                          // onCellChange={onChange}
                          rowIndex={item.id}
                          isChangeText={true}
                          onSaveInDb={handleSaveInDb}
                          idx={parseInt(item?.sortOrder)}
                          cellClassName="px-1 flex-grow-1 value-col"
                        />
                      </td>
                      <td
                        className="sortable-dots align-middle cursor-pointer drag-icon drag-column text-center"
                        tabIndex={0}
                        onClick={() => setCommentingRowID(item.id)}
                        title="Collaboration"
                      >
                        <Image
                          width={16}
                          height={16}
                          src={RightBlackIcon}
                          className="mx-0"
                        />
                      </td>
                      <td
                        className="sortable-dots align-middle cursor-pointer drag-icon drag-column text-center"
                        tabIndex={0}
                        onClick={() => handleAddRow(index + 1)}
                        title="Add row"
                      >
                        <Image
                          width={20}
                          height={20}
                          src={PlusBlackIcon}
                          className="mx-0"
                        />
                      </td>
                      <td
                        className="sortable-dots align-middle cursor-pointer drag-icon drag-column text-center"
                        tabIndex={0}
                        onClick={() => handleDeleteRow(item.id)}
                        title="Delete row"
                      >
                        <Image
                          width={20}
                          height={20}
                          src={MinusBlackIcon}
                          className="mx-0"
                        />
                      </td>
                      <td className="count-column fs-16 align-middle">
                        {" "}
                        <ContentEditableInput
                          cellClassName={"text-center"}
                          text={item.body}
                          isChangeDesc={true}
                          rowIndex={item.id}
                          onSaveInDb={handleSaveInDb}
                          idx={parseInt(item?.sortOrder)}
                        />
                      </td>
                      <SlidingSidePanel
                        isOpen={item?.id === commentingRowID ? true : false}
                        onClose={() => setCommentingRowID(null)}
                        id="report-collab-modal"
                      >
                        <ReportCollaboration
                          commentingRowID={commentingRowID}
                          onClose={() => setCommentingRowID(null)}
                          workspaceId={6}
                        />
                      </SlidingSidePanel>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StringsSection;
