import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "../../ui-components";
import ExistingWordsDropdown from "./existing-words-dropdown";
import { updateReportKeywordsData } from "./utils";

export default function KeywordGeneratorModal(props) {
  const {
    isOpen,
    onClose,
    reportTableData,
    onUpdateReportProjectKeywordsRow,
    onSetKeywordModal,
    onGetReportProjectKeywords,
    onAddRowToreportProject,
    onSetKeyword,
    onCheckIfPatentAvailable,
    onFindKeywordsFromText,
  } = props;
  const [formData, setFormData] = useState({
    projectName: "",
    dataName: "Claims",
    technology: "",
    patentNumber: "",
    searchType: "numberType",
    selectedExistingKeyword: "",
    isValid: null,
    data: null,
    keywords: [],
    text: "",
    isLoading: false,
    isChecking: false,
  });
  const [errors, setErrors] = useState({});
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const datanameMapping = {
    Claims: "CL_EN",
    Title: "TI_EN",
    Abstract: "AB_EN",
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForForm = () => {
    const newErrors = {};
    if (formData.searchType === "textType") {
      if (!formData.text) {
        newErrors.text = "Text is required.";
      }
    }

    if (formData.searchType === "numberType") {
      if (!formData.patentNumber) {
        newErrors.text = "Patent number is required.";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSearchForPatent = async (e) => {
    e.preventDefault();
    if (!validateForForm()) return;
    setFormData((prev) => ({
      ...prev,
      isChecking: true,
    }));
    try {
      if (formData.patentNumber) {
        const result = await onCheckIfPatentAvailable(formData.patentNumber);
        if (result) {
          setFormData((prev) => ({
            ...prev,
            isValid: true,
            data: result?._source,
          }));
        } else {
          setFormData((prev) => ({
            ...prev,
            isValid: false,
            data: null,
          }));
        }
      }
    } catch (error) {
      setFormData((prev) => ({
        ...prev,
        data: null,
      }));
    } finally {
      setFormData((prev) => ({
        ...prev,
        isChecking: false,
      }));
    }
  };

  const handleFindKeywords = async (e) => {
    e.preventDefault();
    setFormData((prev) => ({
      ...prev,
      isLoading: true,
    }));
    try {
      const response = await onFindKeywordsFromText(
        formData.searchType === "numberType"
          ? formData.data[datanameMapping[formData.dataName]]
          : formData.text
      );
      setFormData((prev) => ({
        ...prev,
        keywords: response,
      }));
    } catch (err) {
      console.error("Error Finding for keywords:", err);
    } finally {
      setFormData((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const handleCheckbox = (e) => {
    const value = e.target.value;
    setSelectedKeywords((prev) =>
      prev.includes(value) ? prev.filter((k) => k !== value) : [...prev, value]
    );
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedKeywords(formData.keywords);
    } else {
      setSelectedKeywords([]);
    }
  };

  const handleFieldChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      selectedExistingKeyword: value,
    }));
  };
  const handleApply = async (e) => {
    e.preventDefault();
    let newReportTableData = [...reportTableData];

    const selectedKeywordId = formData.selectedExistingKeyword.code;
    if (selectedKeywordId) {
      const index = newReportTableData.findIndex(
        (item) => item.id === selectedKeywordId
      );
      if (index !== -1) {
        newReportTableData[index].body = `${newReportTableData[index].body} ${
          newReportTableData[index].body ? " | " : ""
        } ${selectedKeywords.join(" | ")}`;
        await onUpdateReportProjectKeywordsRow({
          id: selectedKeywordId,
          body: {
            body: newReportTableData[index].body,
          },
        });
        onSetKeywordModal({
          tableData: [],
        });
        await onGetReportProjectKeywords();
      }
    } else {
      const updatedTableData = updateReportKeywordsData(
        [...reportTableData],
        [...selectedKeywords]
      );

      // Update the existing items in the backend
      await Promise.all(
        updatedTableData
          .filter((row) => row.id)
          .map(async (row) => {
            await onUpdateReportProjectKeywordsRow({
              id: row.id,
              body: {
                title: row.title,
              },
            });
          })
      );

      // Add new rows for newly created items
      const newKeywords = updatedTableData.filter((row) => !row.id);
      if (newKeywords.length > 0) {
        const projectId = parseInt(sessionStorage.getItem("projectId"));
        await onAddRowToreportProject({
          data: newKeywords,
          projectId: projectId,
        });
      }
      onSetKeywordModal({
        selectedKeywords: [],
      });

      onGetReportProjectKeywords();
    }

    onSetKeywordModal({
      selectedKeyword: "",
      selectedKeywordId: "",
    });
    onSetKeyword({
      tableData: [],
    });
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      isStatic={false}
      overlayClass="project-creation-modal"
      className="project-creation-modal-body"
    >
      <div className="modal-content border-0 fs-16">
        <div className="modal-header bg-white border-0 px-4">
          <h5 className="modal-title fs-16 primary-heading-text">
            Please enter patent number or text:
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={onClose}
          ></button>
        </div>
        <div className="modal-body py-0 px-4">
          <form className="px-4 py-3 form-bg border">
            <div className="row align-items-center mb-3">
              <div className="col-1 me-4 d-flex">
                <input
                  className="me-1"
                  type="radio"
                  name="searchType"
                  id={"numberType"}
                  value={"numberType"}
                  checked={formData.searchType === "numberType"}
                  onChange={handleChange}
                />{" "}
                <label
                  htmlFor="numberType"
                  className="col-form-label fw-bold me-2 primary-heading-text"
                >
                  Patent
                </label>
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control fs-16 rounded"
                  id="patentNumber"
                  name="patentNumber"
                  placeholder="Generate Keywords by Patent Number"
                  value={formData.patentNumber}
                  onChange={(e) => {
                    handleChange(e);
                    setFormData((prev) => ({
                      ...prev,
                      isValid: null,
                    }));
                  }}
                  disabled={formData.searchType !== "numberType"}
                />
              </div>
              <div className="col-auto">
                {["Claims", "Abstract", "Title"].map((type, index) => (
                  <div
                    className="form-check form-check-inline cursor-pointer"
                    key={index}
                  >
                    <input
                      className="me-1"
                      type="radio"
                      name="dataName"
                      id={type}
                      value={type}
                      checked={formData.dataName === type}
                      onChange={handleChange}
                      disabled={
                        !formData.isValid &&
                        formData.searchType !== "numberType"
                      }
                    />
                    <label
                      htmlFor={type}
                      className="form-check-label p-0 cursor-pointer"
                    >
                      {type}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-1 me-4 d-flex align-items-start">
                <input
                  className="me-1 text-radio"
                  type="radio"
                  name="searchType"
                  id={"textType"}
                  value={"textType"}
                  checked={formData.searchType === "textType"}
                  onChange={handleChange}
                />{" "}
                <label
                  htmlFor="textType"
                  className="col-form-label fw-bold me-2 primary-heading-text"
                >
                  Text
                </label>
              </div>
              <div className="col">
                <textarea
                  className="form-control fs-16 rounded"
                  id="text"
                  name="text"
                  rows="4"
                  placeholder="Generate Keywords with Text"
                  value={formData.text}
                  onChange={handleChange}
                  style={{ resize: "none" }}
                  disabled={formData.searchType !== "textType"}
                ></textarea>
              </div>
              <div className="col-3 px-0 text-end mt-auto">
                {formData.isChecking ? (
                  <button
                    disabled
                    className="create-project-btn btn-with-blue-border-white-bg fs-16 fw-bold"
                  >
                    Checking..
                  </button>
                ) : formData.searchType === "numberType" &&
                  !formData.isValid ? (
                  <button
                    className="create-project-btn btn-with-blue-border-white-bg fs-16 fw-bold"
                    onClick={handleSearchForPatent}
                  >
                    Search
                  </button>
                ) : (
                  <button
                    className="create-project-btn btn-with-blue-border-white-bg fs-16 fw-bold"
                    onClick={handleFindKeywords}
                  >
                    Generate
                  </button>
                )}
              </div>
            </div>
            {errors && <span>{errors.projectName}</span>}
          </form>
        </div>
        <div className="modal-body py-0 mt-1 px-4">
          <div className="keywords-table">
            <div className="header w-100 d-flex py-1 align-items-center">
              <span className="text">Keywords</span>
              <span className="ms-auto text me-2">Add In:</span>
              <ExistingWordsDropdown
                onChange={handleFieldChange}
                data={reportTableData
                  .filter((i) => i.title)
                  .map((i) => {
                    return {
                      title: i.title
                        .replace(/<[^>]*>/g, "")
                        .replaceAll("&nbsp;", " "),
                      code: i.id,
                    };
                  })}
                placeholder="Select"
              />
            </div>

            <div className="table-body">
              {formData.isLoading ? (
                <div className=" row h-100 col-12" style={{ width: "60vw" }}>
                  {" "}
                  <div className="fw-bold d-flex justify-content-center align-items-center">
                    Loading ...
                  </div>
                </div>
              ) : (
                <div className=" row col-12" style={{ width: "60vw" }}>
                  {formData.keywords &&
                    formData.keywords.map((keyword) => (
                      <div className="col-4 d-flex align-items-center gap-2 py-2">
                        <input
                          className={` cursor-pointer`}
                          type="checkbox"
                          value={`${keyword}`}
                          checked={selectedKeywords.includes(keyword)}
                          onChange={handleCheckbox}
                        />
                        <span>{keyword}</span>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="modal-footer border-0">
          <div className="me-auto d-flex gap-2 px-2 py-2">
            <input
              className={` cursor-pointer`}
              type="checkbox"
              checked={
                formData.keywords.length > 0 &&
                selectedKeywords.length === formData.keywords.length
              }
              disabled={formData.keywords.length === 0}
              onChange={handleSelectAll}
            />
            Select All
          </div>
          <button
            type="button"
            className="btn cancel-btn fs-16"
            onClick={onClose}
          >
            Cancel
          </button>
          <Button
            type="button"
            className="create-project-btn btn-with-blue-border-white-bg fs-16"
            onClick={handleApply}
          >
            Add
          </Button>
        </div>
      </div>
    </Modal>
  );
}
