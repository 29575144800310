import React, { useState } from "react";
import { Table } from "react-bootstrap";
import ImportClaimModal from "./import-claims-modal";

const NewKeyFeatureSection = (props) => {
  const {
    reportTableData,
    onSetPatentClaims,
    onSetReportNotes,
    claimsData,
    isLoadingClaims,
    claimsDataFound,
    searchedPatentNumber,
    onSplitPatentClaims,
    relevantResultReportTableData
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="bg-white px-3 py-2">
        <div className="px-4  py-3 strings-section border">
          <div className="strings-table">
            <div className="table-responsive">
              <Table bordered={false} striped>
                <thead>
                  <tr>
                    <th className="text-center text-nowrap fs-16">
                      Key Features
                    </th>
                    <th className="text-center  fs-16">Definition</th>
                    <th className="text-center fs-16"> Filter</th>
                  </tr>
                </thead>
                <tbody>
                  {reportTableData.filter((i) => i.body).length > 0 ? (
                    reportTableData
                      .filter((i) => i.body)
                      .map((row, index) => (
                        <tr key={index}>
                          <td className="checkbox-column fs-16">{index + 1}</td>
                          <td
                            className="string-column fs-16"
                            dangerouslySetInnerHTML={{ __html: row.body }}
                          ></td>
                          <td className="count-column fs-16">
                            <div className="form-check d-flex justify-content-center align-items-center keyfeature-checkbox">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-center fs-16 py-4">
                        Please add the key features
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <button
              className="apply-filter fs-14 px-3"
              onClick={() => setIsOpen(true)}
            >
              Import Claims
            </button>
            <div>
              <span className="relevant-result-header-text fs-14 px-3 disabled-report-btn">
                Clear
              </span>
              <button className="apply-filter fs-14 px-3 disabled-report-btn">Apply Filter</button>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <ImportClaimModal
          {...props}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSetPatentClaims={onSetPatentClaims}
          onSetData={onSetReportNotes}
          claimsData={claimsData}
          tableData={reportTableData}
          isLoadingClaims={isLoadingClaims}
          claimsDataFound={claimsDataFound}
          searchedPatentNumber={searchedPatentNumber}
          onSplitPatentClaims={onSplitPatentClaims}
        />
      )}
    </>
  );
};

export default NewKeyFeatureSection;
