import React from "react";
import { useNavigate } from "react-router-dom";

function CitedLinks(props) {
  const { onRwireSearchAPI, data, index, length, parameter } = props;

  const navigate = useNavigate();

  const handleClick = (shortCode = "", queryString = "") => {
    const query = `${shortCode}=(${queryString})`;
    navigate(`/en/rwire-patents`);
    onRwireSearchAPI(query, { isResetHighlight: true });
  };

  return (
    <span
      onClick={() => {
        handleClick(parameter, data);
      }}
    >
      <span className="pn-hyperlink">{`${data}`}</span>
      <span>{`${index === length - 1 ? "" : " | "}`}</span>
    </span>
  );
}

export default CitedLinks;
