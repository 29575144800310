import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RwireReportClasess } from "../../components/report";
import {
  setReportClasess,
  reportClasessSearch,
  getPagination,
  updateReportProjectClassesSortOrder,
  getReportProjectClasses,
  updateReportProjectClassesRow,
  addRowToClassesReportProject,
  deleteRowClassesFromReportProject,
} from "../../action/report/report-clasess";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isObjectBlank } from "../../action/result-table";
import ClassesSection from "../../components/report-new/classesSection";

const Container = (props) => {
  const navigate = useNavigate();
  const _id = sessionStorage.getItem("projectId");
  useEffect(() => {
    if (_id) {
      if (isObjectBlank(props.reportTableData)) {
        props.onGetReportProjectClasses();
      }
    } else {
      navigate("/en/existing-project");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  // return <RwireReportClasess {...props} />;
  return <ClassesSection {...props} />;
};

const mapStateToProps = ({ reportClasses }) => ({
  ...reportClasses,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetReportClasess: setReportClasess,
      onReportClassesSearch: reportClasessSearch,
      onGetPagination: getPagination,
      onUpdateClassesSortOrder: updateReportProjectClassesSortOrder,
      onGetReportProjectClasses: getReportProjectClasses,
      onUpdateReportProjectClassesRow: updateReportProjectClassesRow,
      onAddRowToreportProject: addRowToClassesReportProject,
      onDeleteRowFromReportProject: deleteRowClassesFromReportProject,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
