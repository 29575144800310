import React, { useState } from "react";
import { Modal } from "../../../ui-components";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RwireProjectModal } from "../rwire-project-creation-wrapper";

export default function CreateProjectModal(props) {
  const {
    isOpen,
    onClose,
    onCreateReportProject,
    onResetProjectData,
    onSetProjectReport,
    onGetAllProjectUsers,
    onCheckIfPatentAvailable,
    onGetBackwardCitedPatentsList,
    userProjects,
  } = props;
  const [formData, setFormData] = useState({
    projectName: "",
    searchType: "Invalidity/Validity",
    technology: "",
    patentNumber: "",
    workspaceElements: {
      keyFeatures: true,
      keywords: true,
      classes: true,
      assignees: true,
      strings: true,
      results: true,
      checklist: true,
      citations: true,
    },
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        workspaceElements: {
          ...prev.workspaceElements,
          [name]: checked,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    if (type !== "radio") {
      setErrors({});
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.projectName) {
      newErrors.projectName = "Project name is required.";
    } else {
      const isDuplicate = userProjects.some(
        (project) => project.name === formData.projectName
      );
      if (isDuplicate) {
        newErrors.projectName = "Project name already exists.";
      }
    }
    if (
      !formData.patentNumber &&
      formData.searchType === "Invalidity/Validity"
    ) {
      newErrors.patentNumber = "Patent number is required.";
    }
    if (Object.values(formData.workspaceElements).every((v) => !v)) {
      newErrors.workspaceElements =
        "At least one workspace element must be selected.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm()) return;
      setFormData((prev) => ({
        ...prev,
        showModal: true,
      }));
      const bcpList = [];
      const bcpDetailsList = [];
      if (formData.patentNumber) {
        const res = await onCheckIfPatentAvailable(formData.patentNumber);
        if (!res) {
          setErrors((prev) => ({
            ...prev,
            patentNumber: "Enter valid Patent Number.",
          }));
          setFormData((prev) => ({
            ...prev,
            showModal: false,
          }));
          return;
        }

        bcpDetailsList.push(res._source.BCPN);
        bcpList.push(res._source.BCP);
      }
      const indices = Object.values(formData.workspaceElements)
        .map((value, index) => (value ? index + 1 : -1))
        .filter((index) => index !== -1);

      await onCreateReportProject({
        name: `${formData.projectName}${
          formData.patentNumber ? `-${formData.patentNumber}` : ""
        }`,
        workspaceElements: indices.join(),
        projectType: formData.searchType,
        patentNumber: formData.patentNumber || "",
        projectTechnology: formData.projectTechnology || "",
        citedPatents: [""],
      });

      onSetProjectReport({
        name: `${formData.projectName}${
          formData.patentNumber ? `-${formData.patentNumber}` : ""
        }`,
        workspaceElements: indices,
        projectType: formData.searchType,
        projectPatentNumber: formData.patentNumber || "",
        projectTechnology: formData.projectTechnology || "",
      });
      onResetProjectData();
      const id = sessionStorage.getItem("projectId");
      await onGetAllProjectUsers(id);
      setTimeout(() => {
        setFormData((prev) => ({
          ...prev,
          showModal: false,
        }));
        onClose();
        navigate("/en/report");
      }, 1000);
      if (formData.patentNumber) {
        onGetBackwardCitedPatentsList(bcpList[0], bcpDetailsList[0], id);
      }
    } catch (error) {
      setFormData((prev) => ({
        ...prev,
        showModal: false,
      }));
      onClose();
    }
  };

  return (
    <>
      {formData.showModal ? (
        <RwireProjectModal open={formData.showModal} />
      ) : (
        <Modal
          isOpen={isOpen}
          isStatic={false}
          overlayClass="project-creation-modal"
          className="project-creation-modal-body"
        >
          <div className="modal-content border-0 fs-16">
            <div className="modal-header bg-white border-0 px-4">
              <h5 className="modal-title fs-16 primary-heading-text">
                Please enter Project details:
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body py-0 px-4">
              <form className="px-4 py-3 form-bg border">
                <div className="row mb-3">
                  <label
                    htmlFor="projectName"
                    className="px-0 col-sm-3 col-form-label primary-heading-text"
                  >
                    Project Name <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9 position-relative">
                    <input
                      type="text"
                      className={`form-control fs-16 rounded ${
                        errors.projectName ? "is-invalid" : ""
                      }`}
                      id="projectName"
                      name="projectName"
                      placeholder="Enter project name"
                      value={formData.projectName}
                      onChange={handleChange}
                      required
                    />
                    <div className="error-message-container">
                      {errors.projectName && (
                        <div className="invalid-feedback fs-15 d-block text-danger">
                          {errors.projectName}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="px-0 col-sm-3 col-form-label primary-heading-text">
                    Type of Search <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9">
                    <div className="px-2">
                      {[
                        "Invalidity/Validity",
                        "Patentability/Novelty",
                        "FTO/State of Art",
                      ].map((type, index) => (
                        <div
                          className="form-check form-check-inline cursor-pointer"
                          key={index}
                        >
                          <input
                            className="me-1"
                            type="radio"
                            name="searchType"
                            id={type}
                            value={type}
                            checked={formData.searchType === type}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label p-0 cursor-pointer"
                            htmlFor={type}
                          >
                            {type}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    htmlFor="technology"
                    className="px-0 col-sm-3 col-form-label primary-heading-text"
                  >
                    Technology
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control fs-16 rounded"
                      id="technology"
                      name="technology"
                      placeholder="Project Technology"
                      value={formData.technology}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* <div className="row mb-3 workspace-elements">
                  <label className="px-0 col-sm-3 col-form-label primary-heading-text">
                    Workspace Elements <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9 position-relative">
                    <div className="row">
                      {Object.keys(formData.workspaceElements).map(
                        (element) => (
                          <div className="col-sm-4 mb-2" key={element}>
                            <div className="form-check">
                              <input
                                className="form-check-input cursor-pointer"
                                type="checkbox"
                                id={element}
                                name={element}
                                checked={formData.workspaceElements[element]}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label ms-2 pb-0 cursor-pointer"
                                htmlFor={element}
                              >
                                {element.charAt(0).toUpperCase() +
                                  element.slice(1)}
                              </label>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <div className="error-message-container">
                      {errors.workspaceElements && (
                        <div className="invalid-feedback fs-15 d-block text-danger">
                          {errors.workspaceElements}
                        </div>
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="row mb-3">
                  <label
                    htmlFor="patentNumber"
                    className={`${
                      formData.searchType !== "Invalidity/Validity"
                        ? "disabled m-0"
                        : ""
                    } px-0 col-sm-3 col-form-label primary-heading-text`}
                  >
                    Patent Number <span className="text-danger">*</span>
                  </label>
                  <div
                    className={`${
                      formData.searchType !== "Invalidity/Validity"
                        ? "disabled m-0"
                        : ""
                    } col-sm-9 position-relative`}
                  >
                    <input
                      type="text"
                      className={`form-control fs-16 rounded ${
                        errors.patentNumber ? "is-invalid" : ""
                      } `}
                      id="patentNumber"
                      name="patentNumber"
                      placeholder="Patent Number"
                      value={formData.patentNumber}
                      onChange={handleChange}
                      required
                      disabled={formData.searchType !== "Invalidity/Validity"}
                    />
                    <div className="error-message-container">
                      {formData.searchType === "Invalidity/Validity" &&
                        errors.patentNumber && (
                          <div className="invalid-feedback fs-15 d-block text-danger">
                            {errors.patentNumber}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn cancel-btn fs-16"
                onClick={onClose}
              >
                Cancel
              </button>
              <Button
                type="button"
                className="create-project-btn btn-with-blue-border-white-bg fs-16"
                onClick={handleSubmit}
              >
                Create
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
