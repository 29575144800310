import { arrayMove } from "react-sortable-hoc";
import { uuidv4 } from "../../../action/app";
import { transformedString } from "../../../action/patent-view";

export const addRowBelow =
  (rowIndex, tableData, setTableData) => (dispatch) => {
    const updatedTableData = [...tableData];
    const newRow = { body: "", id: uuidv4() };

    if (
      updatedTableData[rowIndex] &&
      typeof updatedTableData[rowIndex].desc !== "undefined"
    ) {
      newRow.desc = "";
    }

    updatedTableData.splice(rowIndex + 1, 0, newRow);

    dispatch(setTableData({ reportTableData: updatedTableData }));
  };

export const addColumnRight =
  (rowIndex, tableData, setTableData) => (dispatch) => {
    const updatedTableData = [...tableData];
    updatedTableData.forEach((row) => {
      row.push("");
    });
    dispatch(setTableData({ reportTableData: updatedTableData }));
  };
export const deleteColumn =
  (colIndex, tableData, setTableData) => (dispatch) => {
    const updatedTableData = [...tableData].map((row) => {
      row.splice(colIndex, 1);
      return row;
    });
    dispatch(setTableData({ reportTableData: updatedTableData }));
  };
export const deleteRow = (index, tableData, setTableData) => (dispatch) => {
  const updatedTableData = [...tableData];
  updatedTableData.splice(index, 1);
  dispatch(setTableData({ reportTableData: updatedTableData }));
};

export const handleCellChange =
  ({
    value,
    rowIndex,
    data,
    onSetData,
    isChangeText = false,
    isChangeDesc = false,
  }) =>
  (dispatch) => {
    const updatedTableData = data.map((i) => {
      if (i.id === rowIndex && isChangeText) {
        return {
          ...i,
          body: value,
        };
      } else if (i.id === rowIndex && isChangeDesc) {
        return {
          ...i,
          desc: value,
        };
      }
      return {
        ...i,
      };
    });
    dispatch(onSetData({ reportTableData: updatedTableData }));
  };

export const convertToObjectArray = (inputObject) => {
  const result = [];
  for (const key in inputObject) {
    if (inputObject.hasOwnProperty(key)) {
      const id = parseInt(key) + 1;
      const label = inputObject[key].CL;
      if (inputObject[key].LG === "eng") {
        result.push({
          id,
          label: label,
          checked: false,
          INDP: inputObject[key].INDP,
        });
      }
    }
  }

  return result;
};

export const splitPatentClaims = (claims) => (dispatch) => {
  const capturedTexts = [];
  // const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const regexBefore = /<claim-text>(.*?)<claim-text>/s;
  const regexInside = /<claim-text>(.*?)<\/claim-text>/gs; // Modified regex

  const descTagRegex = /&lt;Desc\/.*?&gt;/g;

  claims.forEach((claim, index) => {
    const cleanedLabel = claim.label.replace(descTagRegex, "");

    let isPreambleText = false;
    const beforeMatch = regexBefore.exec(cleanedLabel.replace(/(\n)/gm, ""));

    if (beforeMatch) {
      const claimText = transformedString(beforeMatch[1]);
      capturedTexts.push({
        body: `<b>${index + 1}.</b> ${claimText}`,
        INDP: claim.INDP,
      });
      isPreambleText = true;
    }

    let subclaimCounter = 0;

    cleanedLabel
      .replace(/(\n)/gm, "")
      .replace(regexInside, (match, insideText) => {
        if (insideText.trim() === "") {
          return;
        }

        const subclaimNumber = !isPreambleText
          ? `${index + 1}.`
          : `${index + 1}.(${alphabet[subclaimCounter]})`;
        const subclaimText = transformedString(
          insideText.replace(/(\n)/gm, "")
        );
        capturedTexts.push({
          body: `<b>${subclaimNumber}</b> ${subclaimText}`,
          INDP: claim.INDP,
        });

        subclaimCounter++;
      });
  });

  const transformedTexts = capturedTexts.map((text) => ({
    label: text.body,
    id: uuidv4(),
    checked: false,
    INDP: text.INDP,
  }));

  return transformedTexts;
};

export const splitClaimsFromText = (claims) => {
  // The given regular expression will split the string  into an array of substrings at the following points:
  // After a period (.)
  // After a colon (:)
  // After a semicolon (;)
  const splitText = claims.split(/(?<=[.:;])\s*(?=\w)/);
  return splitText;
};

export const findGreatestId = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return null; // Return null for empty or non-array input
  }

  // Sorting the array based on the 'id' property
  data.sort((a, b) => a.id - b.id);

  // Picking the last element as it will have the greatest 'id'
  return data[data.length - 1].id;
};

export const updateSortOrder = (data, oldIndex, newIndex) => {
  const newData = [...data];
  const movedItem = arrayMove(newData, oldIndex, newIndex);

  const updatedData = movedItem.map((item, index) => {
    return {
      ...item,
      sortOrder: (index + 1).toString(),
    };
  });
  const resultObject = updatedData.reduce((result, item) => {
    result[item.id] = item.sortOrder;
    return result;
  }, {});
  const requestData = {
    "sort-order": JSON.stringify(resultObject),
  };
  return requestData;
};

export const downloadFileUsingS3URL = (s3FilePath, fileName, index) => {
  const downloadIcon = document.getElementById(
    `collab-file-download-icon-${index}`
  );
  const loader = document.getElementById(
    `collab-file-download-loader-${index}`
  );
  downloadIcon.style.display = "none";
  loader.style.display = "block";
  fetch(s3FilePath)
    .then((response) => response.blob())
    .then((blob) => {
      const filename = fileName;
      const fileBlob = new Blob([blob], { type: blob.type });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(fileBlob);
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      loader.style.display = "none";
      downloadIcon.style.display = "block";
    })
    .catch((err) => {
      loader.style.display = "none";
      downloadIcon.style.display = "block";
    });
};
