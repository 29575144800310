import React, { useEffect, useState } from "react";
import replaceSpecialChars from "../../../common/replace-special-chars";
import truncateText from "../../../common/truncate-text";
import { HighlightFromQueryOrManually } from "../../../container/highlight";

function RwireClaims(props) {
  const { displayClaims, index } = props;
  const [claims, setClaims] = useState(displayClaims.join(""));
  const [claimsToggle, setClaimsToggle] = useState(false);
  useEffect(() => {
    const button = document.querySelector(`.claims_no_${index}`);
    button && button.addEventListener("click", handleClaimsToggle);
    return () => {
      button && button.removeEventListener("click", handleClaimsToggle);
    };
  });
  useEffect(() => {
    let tempClaims = "";

    if (!displayClaims || displayClaims.join("").length === 0) {
      tempClaims = "Data not available";
    } else if (displayClaims.join("").length < 1000) {
      tempClaims = replaceSpecialChars(displayClaims.join(""));
    } else if (claimsToggle) {
      // for ...less
      tempClaims =
        replaceSpecialChars(displayClaims.join("")) +
        `<span class="font-weight-bold cursor-pointer claims_no_${index} ">  Less</span></p>`;
    } else {
      // for ...more
      tempClaims =
        replaceSpecialChars(truncateText(displayClaims.join(""))) +
        `<span class="font-weight-bold cursor-pointer claims_no_${index} ">  More</span>`;
    }
    setClaims(tempClaims);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimsToggle, displayClaims]);

  const handleClaimsToggle = () => {
    setClaimsToggle(!claimsToggle);
  };

  return (
    <td className="result-table-claims dynamic-height">
      <div className="result-table-inner-div">
        <HighlightFromQueryOrManually>
          {claims.replace(/&nbsp;/g, " ")}
        </HighlightFromQueryOrManually>
      </div>
    </td>
  );
}

export default RwireClaims;
