import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RwireDashboardWrapper } from "../../components/report";
import {
  getReportProjectDataById,
  getReportProjectNameById,
  setProjectReport,
  updateReportProject,
  getReportProjects
} from "../../action/report/report-project";

const Container = (props) => {
  return <RwireDashboardWrapper {...props} />;
};

const mapStateToProps = ({ project }) => ({
  ...project,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetProjectReport: setProjectReport,
      onUpdateReportProject: updateReportProject,
      onGetReportProjectNameById: getReportProjectNameById,
      onGetReportProjectDataById: getReportProjectDataById,
      onGetReportProjects: getReportProjects
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
