import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getReportProjectNotes,
  setReportNotes,
} from "../../action/report/report-notes";
import { useEffect } from "react";
import KeyFeaturesList from "../../pages/rwire-patent-view/key-features-list";
import { setFullView } from "../../action/patent-view";

const Container = (props) => {
  const projectId = sessionStorage.getItem("projectId");
  const { onGetReportProjectNotes } = props;
  useEffect(() => {
    if (projectId) {
      onGetReportProjectNotes();
    }
  }, [onGetReportProjectNotes, projectId]);

  return <KeyFeaturesList {...props} />;

};

const mapStateToProps = ({
  reportNotes: { reportTableData = [], activeKeyFeature },
  fullView: { userAnnotations }
}) => ({
  reportTableData,
  activeKeyFeature,
  userAnnotations
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetReportProjectNotes: getReportProjectNotes,
      onSetReportNotes: setReportNotes,
      onSetFullView: setFullView,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
