import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";

const SplitClaims = (props) => {
  const {
    splittedClaims,
    setSplittedClaims,
    selectedClaims,
    setSelectedClaims,
    checklistErrorMessage,
    setChecklistErrorMessage,
  } = props;

  const [textToSplit, setTextToSplit] = useState("");
  const [caretPosition, setCaretPosition] = useState(0);
  const [caretErrorMessage, setCaretErrorMessage] = useState("");
  const textAreaRef = useRef(null);

  const fillArray = (count) => {
    return Array.from({ length: count }, (_, index) => index);
  };

  const handleChekSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedClaims(fillArray(splittedClaims.length));
    } else {
      setSelectedClaims([]);
    }
  };

  // Single Checkbox Change Handler
  const handleCheckboxNewChange = (index) => {
    if (selectedClaims.includes(index)) {
      setSelectedClaims(
        selectedClaims.filter((selectedIndex) => selectedIndex !== index)
      );
    } else {
      setSelectedClaims([...selectedClaims, index]);
    }
    setChecklistErrorMessage("");
  };

  // Join Claims Handler
  const handleJoinClaims = () => {
    const selectedIndexes = selectedClaims.sort((a, b) => a - b);
    if (selectedIndexes.length < 2) {
      setChecklistErrorMessage("Select two or more claims to join");
      return;
    }
    const areConsecutive = selectedIndexes.every(
      (index, i) => i === 0 || index === selectedIndexes[i - 1] + 1
    );
    if (!areConsecutive) {
      setChecklistErrorMessage("Select two or more consecutive claims to join");
      return;
    }
    const newSplittedClaims = [...splittedClaims];
    const joinedClaim = splittedClaims
      .filter((claim, index) => selectedClaims.includes(index))
      .join(" ");
    selectedClaims.forEach((index) => {
      newSplittedClaims[index] = "";
    });
    newSplittedClaims[selectedClaims[0]] = joinedClaim;
    const filteredClaims = newSplittedClaims.filter((claim) => claim);
    setSplittedClaims(filteredClaims);
    setSelectedClaims([]);
    setTextToSplit("");
  };

  // Split Claim Text Handler
  const handleSplitClaimText = () => {
    if (selectedClaims.length !== 1) {
      setChecklistErrorMessage("Please select a single feature");
      return;
    }

    const selectedClaimIndex = selectedClaims[0];
    const selectedClaim = splittedClaims[selectedClaimIndex];

    setTextToSplit(selectedClaim);
  };

  // Textarea Select Handler
  const handleSelect = () => {
    if (textAreaRef.current) {
      setCaretPosition(textAreaRef.current.selectionStart);
      setCaretErrorMessage("");
    }
  };

  // Split Claims Handler
  const handleSplit = () => {
    if (caretPosition === 0) {
      setCaretErrorMessage(
        "Please place the cursor in the textarea to split it."
      );
    } else if (caretPosition === textToSplit.length) {
      return;
    } else {
      const selectedClaimIndex = selectedClaims[0];
      const selectedClaim = splittedClaims[selectedClaimIndex];

      const firstPart = selectedClaim.slice(0, caretPosition);
      const secondPart = selectedClaim.slice(caretPosition);

      const newSplittedClaims = [...splittedClaims];

      newSplittedClaims.splice(selectedClaimIndex, 1, firstPart, secondPart);

      setCaretPosition(0);
      setSplittedClaims(newSplittedClaims);
      setTextToSplit("");
      setSelectedClaims([]);
    }
  };

  return (
    <div className="split claim px-2">
      {splittedClaims && splittedClaims.length > 0 && (
        <div className="split-result-section">
          <div className="d-flex single-result align-items-center">
            <div className="text text-left"></div>
            <div className="checkbox">
              <input
                type="checkbox"
                className="checkbox"
                checked={selectedClaims.length === splittedClaims.length}
                onChange={(e) => handleChekSelectAll(e)}
              />
            </div>
          </div>
          {splittedClaims.map((claim, index) => (
            <div key={index} className="d-flex single-result">
              <div className="text text-left">{claim}</div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={selectedClaims.includes(index)}
                  onChange={() => handleCheckboxNewChange(index)}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      <p className="me-auto claims-err-message">{checklistErrorMessage}</p>

      {splittedClaims && splittedClaims.length > 0 && (
        <div className="py-2">
          <Button className="btn-blue" onClick={handleJoinClaims}>
            Join Claims
          </Button>
          <Button className="btn-white" onClick={handleSplitClaimText}>
            Split Claim
          </Button>
        </div>
      )}

      {textToSplit && (
        <div className="w-100 pt-2 text-left d-flex flex-column">
          <div className="heading-text">
            Place cursor in text to split it into a new feature
          </div>
          <textarea
            value={textToSplit}
            ref={textAreaRef}
            onSelect={handleSelect}
            className="new-report-textarea"
          />
          <div className="claims-err-message">{caretErrorMessage}</div>
          <Button className="ms-0 mt-1 btn-white" onClick={handleSplit}>
            Split it into a new claim element
          </Button>
        </div>
      )}
    </div>
  );
};

export default SplitClaims;
