import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RwireReportRelevantResults } from "../../components/report";
import {
  setReportRelevantResult,
  getReportProjectRelevantResultFromPN,
  addKeyFeaturesRelevantResult,
  fetPatentDetails,
  deleteRow,
} from "../../action/report/report-relevant-results";
import { getReportProjectNotes } from "../../action/report/report-notes";
import { setFilterFields } from "../../action/result-table";
import RelevantResultSection from "../../components/report-new/relevantResultsection";
import { useEffect } from "react";

const Container = (props) => {
  const projectId = sessionStorage.getItem("projectId");
  const { onGetReportProjectRelevantResultFromPN } = props;

  useEffect(() => {
    onGetReportProjectRelevantResultFromPN({ projectId, selectedId: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);
  // return <RwireReportRelevantResults {...props} />;
  return <RelevantResultSection {...props} />;
};

const mapStateToProps = ({ reportRelevantResults, reportNotes }) => ({
  ...reportRelevantResults,
  ...reportNotes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetReportRelevantResults: setReportRelevantResult,
      onGetReportProjectRelevantResultFromPN:
        getReportProjectRelevantResultFromPN,
      onGetReportProjectNotes: getReportProjectNotes,
      onAddKeyFeaturesRelevantResult: addKeyFeaturesRelevantResult,
      onSetFilter: setFilterFields,
      onRwireSearchAPI: fetPatentDetails,
      onRemove: deleteRow,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
