/* eslint-disable no-console */
import { fetch } from "../service/fetch";

export const setChart = (payload) => ({
  type: "CHART_SET",
  payload: {
    ...payload,
  },
});

export const setGraphConfig = (payload) => ({
  type: "CHART_SET",
  payload: {
    ...payload,
  },
});

export const setUpdatedGraphConfig = (payload) => ({
  type: "CHART_SET",
  payload: {
    ...payload,
  },
});

export const fetchGraphConfig = () => async (dispatch) => {
  try {
    const response = await fetch(
      {
        url: "/api/graph-config/get",
      },
      "GET"
    );

    if (response.length > 0) {
      const data = await response.json();
      dispatch(setChart({ chartList: [...data] }));
    } else {
      console.error("Error fetching graph config:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching graph config:", error);
  }
};

export const saveGraphConfig = (body) => async (dispatch) => {
  try {
    const response = await fetch(
      {
        url: "/api/graph-config",
        body: { graphJson: body },
      },
      "POST"
    );

    console.log("response", response);

    if (response) {
      dispatch(setUpdatedGraphConfig(body));
    }
  } catch (error) {
    console.error("Error saving graph config:", error);
  }
};

export const clearGraphConfig = () => async (dispatch) => {
  try {
    await fetch(
      {
        url: "/api/graph-config",
      },
      "DELETE"
    );

    dispatch(setGraphConfig([]));
  } catch (error) {
    console.error("Error clearing graph config:", error);
  }
};
