/* eslint-disable no-console */
import {
  formatUserQueryInput,
  getWordsSynonymsArray,
} from "../keyword-suggestor";
import { fetch } from "../../service/fetch";
import { synonyms } from "../../resources/data/synonyms";

export const setReportKeyword = (payload) => ({
  type: "KEYWORD_SET",
  payload: {
    ...payload,
  },
});

export const setSelectedRows = (selectedRows) => (dispatch) => {
  dispatch(setReportKeyword(selectedRows));
};

export const search = (keyword) => async (dispatch, getState) => {
  const formattedKeyword = formatUserQueryInput(keyword);
  const queryWords = formattedKeyword.split(" , ");
  const tableData = await getWordsSynonymsArray(queryWords, synonyms);
  dispatch(
    setReportKeyword({
      tableData,
    })
  );
};

export const getReportProjectKeywords = () => async (dispatch) => {
  try {
    var response = {};
    const id = sessionStorage.getItem("projectId");
    if (id) {
      response = await fetch(
        {
          url: `/api/project-keywords/project/${id}`,
        },
        "GET"
      );
      dispatch(
        setReportKeyword({
          reportTableData: [...response.result],
        })
      );
    }
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const updateReportProjectKeywordsRow =
  ({ id, body }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-keywords/${id}`,
          body,
        },
        "PUT"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const updateReportProjectKeywordsSortOrder =
  (body) => async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-keywords/sort-order`,
          body,
        },
        "POST"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const addRowToKeywordsReportProject = (body) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-keywords`,
        body,
      },
      "POST"
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const deleteRowKeywordsFromReportProject = (id) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-keywords/${id}`,
      },
      "DELETE"
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};
