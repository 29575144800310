import React, { useState } from "react";
import "./style.scss";
import { services } from "../../resources/data/services-list";
import FirstSection from "./first-section";
import SecondSection from "./second-section";
import LastSection from "./last-section";
import ThirdSection from "./third-section";

const RwireService = () => {
  const [selectedService, setSelectedService] = useState(services[0]);

  const handleClick = (service) => {
    setSelectedService(service);
  };

  return (
    <>
      <FirstSection />
      <SecondSection
        services={services}
        selectedService={selectedService}
        handleClick={handleClick}
      />
      <ThirdSection />
      <LastSection />
    </>
  );
};

export default RwireService;
