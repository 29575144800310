import React from "react";
import "./style.scss";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";

const SecondPart = ({ leftFeatures, activeIndex, handleToggle }) => (
  <div className="second_part d-flex px-4 py-4">
    <div className="">
      <div className="our_features_main d-flex flex-row w-100" >
        <div className="accordion px-3 py-4 equal-width">
          {leftFeatures.filter((item, index) => index < 7).map((feature, index) => (
            <div key={index} className="accordion-item mb-2">
              <div
                className="accordion-title ml-2"
                onClick={() => handleToggle(index)}
              >
                <div className="d-flex flex-row">
                  <img
                    className="mr-2 accordion_tick_image"
                    src={RWIRE_IMAGES.RwireTickImage}
                    alt=""
                  />
                  <div className={`${activeIndex === index  ? "" : "title-trim"}`}> {feature.title}   </div>
                </div >
                <span className="mr-3">
                  {activeIndex === index ? (
                    <img src={RWIRE_IMAGES.RwireMinusImage} alt="collapse" />
                  ) : (
                    <img src={RWIRE_IMAGES.RwirePlusImage} alt="expand" />
                  )}
                </span>
              </div>

              <div
                className={`accordion-content ${activeIndex === index ? "active ml-4" : ""
                  }`}
              >
                <hr className="qa-line" />
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="accordion px-3 py-4 equal-width">
          {leftFeatures.filter((item, index) => index >= 7).map((feature, index) => (
            <div key={index + 7} className="accordion-item mb-2">
              <div
                className="accordion-title ml-2"
                onClick={() => handleToggle(index + 7)}
              >
                <div className="d-flex flex-row">
                  <img
                    className="mr-2 accordion_tick_image"
                    src={RWIRE_IMAGES.RwireTickImage}
                    alt=""
                  />
                  <div className={`${activeIndex === index + 7 ? "" : "title-trim"}`}>{feature.title}</div>
                </div>
                <span className="mr-3">
                  {activeIndex === index + 7 ? (
                    <img src={RWIRE_IMAGES.RwireMinusImage} alt="collapse" />
                  ) : (
                    <img src={RWIRE_IMAGES.RwirePlusImage} alt="expand" title={feature.title} />
                  )}
                </span>
              </div>

              <div
                className={`accordion-content ${activeIndex === index + 7 ? "active ml-4" : ""
                  }`}
              >
                <hr className="qa-line" />
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

      </div>
    </div>
  </div>
);

export default SecondPart;
