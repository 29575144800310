import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import Reducer from "./reducer";

// Set up Redux DevTools if it's available, or use default compose
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

// Get the persisted state from localStorage, if available
const persistedState = localStorage.getItem("reduxState")
  ? JSON.parse(localStorage.getItem("reduxState"))
  : undefined;

// Set up middleware (like thunk) and enhancers
const enhancer = composeEnhancers(applyMiddleware(thunk));

// Create the Redux store with the persisted state
const store = createStore(Reducer, persistedState, enhancer);

if (persistedState) {
  localStorage.removeItem("reduxState");
}

export default store;
