/* eslint-disable no-console */
import { Modal } from "rsuite";
import React, { useState, useEffect } from "react";
import { createExportTemplate } from "../../action/export";
import CloseIcon from "@rsuite/icons/Close";
import { Message, toaster } from "rsuite";

const RwireSaveTemplate = (props) => {
  const {
    loadModal,
    setLoadModal,
    selectedFields,
    fieldNameFromShortCodeExport,
    onCreateTemplate,
    onSetSelectedField,
    onSetDefaultExportTempate,
    onGetExportTemplates,
  } = props;

  const [templateName, setTemplateName] = useState("");
  const [setDefault, setSetDefault] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);

  useEffect(() => {
    // Reset validation message and disabled state when the modal is opened
    if (loadModal) {
      setValidationMessage("");
      setSaveDisabled(false);
    }
  }, [loadModal]);

  const handleSaveTemplate = async () => {
    if (templateName.trim() === "") {
      setValidationMessage("Template name is required.");
      setSaveDisabled(true);
      setSaveClicked(true);
      return;
    }
    setValidationMessage("");
    setSaveDisabled(false);
    const template = {
      name: templateName,
      fields: selectedFields.join(),
    };

    try {
      const existingTemplateResponse = await onGetExportTemplates(1);
      const isExist = existingTemplateResponse.some((templates) => {
        return templates.name === templateName;
      });
      if (isExist === true) {
        setValidationMessage("Template name already exists.");
        setSaveDisabled(true);
        setSaveClicked(true);
        return;
      }
      const response = await createExportTemplate(template);
      if (response && response.status === "success") {
        if (setDefault) {
          const setDefaultResponse = await onSetDefaultExportTempate({
            fields: selectedFields.join(),
          });

          if (setDefaultResponse === "success") {
          } else {
            console.error("Error setting default export preferences");
          }
        }
      }
      toaster.push(
        <Message type="success">Template created successfully</Message>
      );
    } catch (error) {
      console.error("Error creating the template:", error);
    }

    setTemplateName("");
    onCreateTemplate(template);
    setLoadModal(false);
  };

  return (
    <>
      {loadModal && (
        <Modal
          className="modalClassFilterLoad"
          backdrop="true"
          keyboard={false}
          open={true}
          onClose={() => {
            setLoadModal(!loadModal);
          }}
        >
          <div className="export-report-properties">
            <div className="d-flex justify-content-between download-center-header light-blue-background">
              <div className="download-center-text">
                Export & report template properties
              </div>
              <div
                className="cursor-pointer close-btn"
                title="Close"
                onClick={() => {
                  setLoadModal(!loadModal);
                  setValidationMessage("");
                  setSaveClicked(false);
                }}
              >
                <CloseIcon />
              </div>{" "}
            </div>
            <div className="loadtempltedexport">
              <div className="Properties-export-load">Properties</div>
              <div className="email-button-center-download">
                <div className="d-flex justify-content-center align-center-load">
                  <div className="template-label-export">Template Name</div>
                  <div
                    className={`input-group download-load ${
                      saveClicked && templateName.trim() === ""
                        ? "input-error"
                        : ""
                    }`}
                  >
                    <input
                      type="text"
                      className={`form-control ${
                        saveClicked && templateName.trim() === ""
                          ? "input-error-border"
                          : ""
                      }`}
                      value={templateName}
                      onChange={(e) => {
                        setTemplateName(e.target.value);
                        setSaveClicked(false);
                        setValidationMessage("");
                        setSaveDisabled(false);
                      }}
                    />
                  </div>
                </div>
                {validationMessage && (
                  <div className="text-danger mb-3 ml-2 validation-msg ">
                    {validationMessage}
                  </div>
                )}
                <div className="selected-filed-save-tempate-main">
                  <div className="select-export mt-3">
                    Selected Fields ({selectedFields.length})
                  </div>
                  <div className="selected-filed-save-tempate d-flex justify-content-start">
                    {selectedFields.map((value, index) => (
                      <React.Fragment key={index}>
                        <div className="select-list">
                          {fieldNameFromShortCodeExport(value)}
                        </div>
                        {index !== selectedFields.length - 1 && (
                          <span>, &nbsp;</span>
                        )}

                        {index !== selectedFields.length - 1}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-left pl-4 disabled">
              <label>
                {" "}
                <input
                  type="checkbox"
                  checked={setDefault}
                  onChange={(event) => {
                    setSetDefault(event.target.checked);
                    if (event.target.checked) {
                      onSetSelectedField({
                        selectedFields: selectedFields,
                      });
                    }
                  }}
                  disabled={setDefault}
                />
                <span className="p-2">
                  Make these my default export preferences
                </span>
              </label>
            </div>
            <div className="d-flex justify-content-between footer-load">
              <div></div>
              <div className="d-flex justify-content-between">
                <button
                  className="email-send-download-cancel"
                  onClick={() => {
                    setLoadModal(!loadModal);
                    setSaveClicked(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="email-send-download-save"
                  onClick={handleSaveTemplate}
                  disabled={saveDisabled}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default RwireSaveTemplate;
