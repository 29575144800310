import React from "react";
import RwireReportClasess from "../../container/report/rwire-report-clasess";
import RwireReportKeyword from "../../container/report/rwire-report-keyword";
import InventorAssigneeSection from "./inventor-assignee-section";

const PatentAnalysisTool = () => {
  return (
    <div className="patent-analysis-tool px-3">
      <div className="row px-3 justify-content-between  align-items-stretch">
        <div className="keyword-section-container px-2 py-2">
          <RwireReportKeyword />
        </div>
        <div className="keyword-section-container px-2 py-2">
          <RwireReportClasess />
        </div>
        <div className="keyword-section-container px-2 py-2">
          <InventorAssigneeSection />
        </div>
      </div>
    </div>
  );
};

export default PatentAnalysisTool;
