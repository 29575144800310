import React from "react";
import SortableTable from "./single-sortable"; // Adjust the path as necessary
import { arrayMove } from "react-sortable-hoc";
import { updateSortOrder } from "../../ui-components/functions/notes/utils";

// Inventors Component
const Inventors = ({
  reportTableData,
  onUpdateReportProjectInventorRow,
  onSetReportInventor,
  onUpdateInventorSortOrder,
  onAddRowToreportProject,
  onGetReportProjectInventors,
  onDeleteRowFromReportProject,
}) => {
  const handleSortEnd = async ({ oldIndex, newIndex }) => {
    onSetReportInventor({
      reportTableData: arrayMove(reportTableData, oldIndex, newIndex),
    });
    const bodyData = updateSortOrder(reportTableData, oldIndex, newIndex);
    await onUpdateInventorSortOrder(bodyData);
  };

  const handleChange = (event, rowIndex, options) => {
    const value = event.target.value;
    const updatedTableData = reportTableData.map((i) => {
      if (i.id === rowIndex) {
        return {
          ...i,
          body: value,
        };
      }
      return {
        ...i,
      };
    });

    onSetReportInventor({
      reportTableData: updatedTableData,
    });
  };

  const handleSaveInDb = async (value, rowIndex, idx, options) => {
    if (reportTableData[idx].body !== value) {
      await onUpdateReportProjectInventorRow({
        id: rowIndex,
        body: {
          body: value,
        },
      });
    }
  };

  const handleAddRow = async (rowIndex) => {
    const _id = sessionStorage.getItem("projectId");
    await onAddRowToreportProject({
      data: [
        {
          body: "",
          order: parseInt(`${rowIndex + 1}`),
        },
      ],
      projectId: parseInt(_id),
    });
    await onGetReportProjectInventors();
  };

  const handleDeleteRow = async (id) => {
    if (reportTableData.length === 1) {
      return;
    }

    await onDeleteRowFromReportProject(id);
    await onGetReportProjectInventors();
  };
  return (
    <div className="mb-2">
      <p className="inventor-section-header-text fs-16 mb-2">Inventors</p>
      <SortableTable
        items={reportTableData}
        workspaceId={5}
        onSortEnd={handleSortEnd}
        onChange={handleChange}
        onSaveInDb={handleSaveInDb}
        onDelete={handleDeleteRow}
        onAddRow={handleAddRow}
      />
    </div>
  );
};

export default Inventors;
