import { mergeObjects } from "../../action/patent-view";
import { useState, useEffect } from "react";

const KeyFeatureNumberList = (props) => {
  const {
    userAnnotations,
    onSetFullView,
    item,
    patentId,
    keyFeatures,
    removeKeyFeatureDetails,
  } = props;

  const [patentNumberCount, setPatentNumberCount] = useState(0);
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  useEffect(() => {
    // Count of key features for the current patentId
    const count = keyFeatures.reduce((total, feature, index) => {
      if (
        feature.patentNumber === patentId &&
        index < keyFeatures.findIndex((f) => f.id === item.id)
      ) {
        return total + 1;
      }
      return total;
    }, 0);

    setPatentNumberCount(count + 1); // Increment by 1 to start count from 1
  }, [keyFeatures, patentId, item.id]);

  const handleHighlight = () => {
    if (patentId === item.patentNumber) {
      onSetFullView({
        userAnnotations: mergeObjects(
          userAnnotations,
          JSON.parse(item.highlight)
        ),
      });
    }

    setTimeout(() => {
      var targetElement = document.getElementById(
        Object.values(JSON.parse(item.highlight))[0][0].Uid
      );

      if (targetElement) {
        // Scroll to the target element
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, 500);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    const uid = Object.values(JSON.parse(item.highlight))[0][0].Uid;
    removeKeyFeatureDetails(uid);

    // Optionally, can also remove the highlight from the full view
    let userAnnotationsNew = userAnnotations;
    Object.keys(userAnnotationsNew).forEach((key) => {
      userAnnotationsNew[key] = userAnnotationsNew[key].filter(
        (annotation) => annotation.Uid !== uid
      );
    });

    onSetFullView({
      userAnnotations: userAnnotationsNew,
    });
  };

  return (
    <div
      className="m-1 number-card"
      onClick={handleHighlight}
      onMouseEnter={() => setShowCloseIcon(true)}
      onMouseLeave={() => setShowCloseIcon(false)}
    >
      <div className="number">{patentNumberCount}</div>
      {showCloseIcon && (
        <div className="close-icon" onClick={handleDelete}></div>
      )}
    </div>
  );
};

export default KeyFeatureNumberList;
