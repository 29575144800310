export const leftFeatures = [
    {
        title: "What is the Researchwire Patent Database?",
        description:
            "The Researchwire Patent Database is an online platform that provides comprehensive access to global patent data. It enables users to search, analyze, and monitor patent information from various jurisdictions, helping businesses, researchers, and legal professionals make informed decisions.",
    },
    {
        title: "Who can use the Researchwire Patent Database?",
        description:
            "The database is designed for patent professionals, researchers, business executives, legal teams, and anyone interested in patent information. Whether you're looking to protect intellectual property, conduct competitive analysis, or explore innovation trends, the Researchwire Patent Database is a valuable tool.",
    },
    {
        title: "What types of patents are included in the Researchwire Patent Database?",
        description:
            "The database includes patents from multiple jurisdictions around the world, covering a wide range of industries and technologies. It includes granted patents, published patent applications, and utility models.",
    },
    {
        title: "How do I search for patents in the Researchwire Patent Database?",
        description:
            "Users can perform searches using various criteria, including keywords, patent numbers, assignees, inventors, classifications, and filing dates. Advanced search options are available to refine and filter search results further.",
    },
    {
        title: "Can I track patent activities using the Researchwire Patent Database?",
        description:
            "Yes, the database allows users to set up alerts to monitor new patent filings, changes to existing patents, and other relevant activities. This feature is particularly useful for staying updated on competitors or tracking technological developments.",
    },
    {
        title: "How frequently is the data updated in the Researchwire Patent Database?",
        description:
            "The database is updated regularly to ensure that users have access to the most current patent information. Updates may vary depending on the jurisdiction, but efforts are made to keep the data as up-to-date as possible.",
    },
    {
        title: "Can I download patent documents from the Researchwire Patent Database?",
        description:
            "Yes, users can download full-text patent documents, including specifications, claims, drawings, and legal statuses. Download formats typically include PDF and XML.",
    },
    {
        title: "Is there a cost associated with using the Researchwire Patent Database?",
        description:
            "The database offers various subscription plans, including free trials and premium access. Pricing depends on the level of access required, such as the number of users, data coverage, and additional features like analytics tools.",
    },
    {
        title: "How secure is the Researchwire Patent Database?",
        description:
            "The platform uses industry-standard security measures to protect user data and ensure the confidentiality of searches and stored information. Regular audits and updates are conducted to maintain the highest security standards.",
    },
    {
        title: "How do I get support if I encounter issues with the Researchwire Patent Database?",
        description:
            "Support is available through various channels, including email, phone, and live chat. Additionally, a comprehensive help center with guides and tutorials is available to assist users with common issues and questions.",
    },
    {
        title: "Can I access the Researchwire Patent Database from mobile devices?",
        description:
            "Yes, the database is accessible from most mobile devices via a web browser. A dedicated mobile app may also be available for enhanced mobile experience.",
    },
    {
        title: "Does the Researchwire Patent Database offer any training or tutorials?",
        description:
            "Yes, Researchwire offers training sessions, webinars, and tutorials to help users make the most of the database's features. These resources are designed to cater to different skill levels, from beginners to advanced users.",
    },
    {
        title: "How can I request a demo of the Researchwire Patent Database?",
        description:
            "You can request a demo by visiting the Researchwire website and filling out a demo request form. A representative will contact you to schedule a personalized demonstration.",
    },
];

