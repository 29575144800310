import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  addRowToAssigneeReportProject,
  deleteRowFromAssigneeReportProject,
  getReportProjectAssignees,
  setReportAssignee,
  updateReportProjectAssigneesRow,
  updateReportProjectAssigneesSortOrder,
} from "../../action/report/report-assignee";
import { useEffect } from "react";
import Assignees from "../../components/report-new/assignees";

const Container = (props) => {
  const { onGetReportProjectAssignees } = props;
  const projectId = sessionStorage.getItem("projectId");
  useEffect(() => {
    onGetReportProjectAssignees();
  }, [projectId, onGetReportProjectAssignees]);

  return <Assignees {...props} />;
};

const mapStateToProps = ({ reportAssignee }) => ({
  ...reportAssignee,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetReportAssignee: setReportAssignee,
      onUpdateAssigneeSortOrder: updateReportProjectAssigneesSortOrder,
      onGetReportProjectAssignees: getReportProjectAssignees,
      onUpdateReportProjectAssigneeRow: updateReportProjectAssigneesRow,
      onAddRowToreportProject: addRowToAssigneeReportProject,
      onDeleteRowFromReportProject: deleteRowFromAssigneeReportProject,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
