import React, { useEffect, useState } from "react";
import ReportClassesModal from "../../container/report/report-classes-modal";
import ContentEditableInput from "./ContentEditableInput";
import SortableTable from "./sortable-table";
import { arrayMove } from "react-sortable-hoc";
import { updateSortOrder } from "../../ui-components/functions/notes/utils";

const ClassesSection = (props) => {
  const {
    onSetReportClasess,
    reportTableData,
    onUpdateClassesSortOrder,
    selectedClassType,
    onUpdateReportProjectClassesRow,
    onAddRowToreportProject,
    onGetReportProjectClasses,
    onDeleteRowFromReportProject,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState(reportTableData);

  useEffect(() => {
    setItems(reportTableData?.[selectedClassType]);
  }, [reportTableData, selectedClassType]);

  const handleChange = (e) => {
    onSetReportClasess({ selectedClassType: e.target.value });
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const selectedReportTable = reportTableData[selectedClassType];
    const updatedData = arrayMove(selectedReportTable, oldIndex, newIndex);

    onSetReportClasess({
      reportTableData: {
        ...reportTableData,
        [selectedClassType]: updatedData,
      },
    });
    const bodyData = updateSortOrder(selectedReportTable, oldIndex, newIndex);
    await onUpdateClassesSortOrder({
      "sort-order": bodyData["sort-order"],
      type: selectedClassType.toUpperCase(),
    });
    setItems(updatedData);
  };

  const handleInputChange = (event, rowIndex, options) => {
    const value = event.target.value;
    const updatedTableData = reportTableData[selectedClassType].map((i) => {
      if (i.id === rowIndex && options.isChangeText) {
        return {
          ...i,
          title: value,
        };
      } else if (i.id === rowIndex && options.isChangeDesc) {
        return {
          ...i,
          body: value,
        };
      }
      return {
        ...i,
      };
    });

    onSetReportClasess({
      reportTableData: {
        ...reportTableData,
        [selectedClassType]: [...updatedTableData],
        selectedRows: [],
      },
    });
  };
  const handleSaveInDb = async (value, rowIndex, idx, options) => {
    if (options.isChangeText) {
      await onUpdateReportProjectClassesRow({
        id: rowIndex,
        body: {
          classes: value,
        },
      });
    } else if (options.isChangeDesc) {
      await onUpdateReportProjectClassesRow({
        id: rowIndex,
        body: {
          body: value,
        },
      });
    }
  };

  const handleAddRow = async (rowIndex) => {
    const _id = sessionStorage.getItem("projectId");
    await onAddRowToreportProject({
      type: selectedClassType.toUpperCase(),
      data: [
        {
          classes: "",
          body: "",
          order: parseInt(`${rowIndex + 1}`),
        },
      ],
      projectId: parseInt(_id),
    });
    await onGetReportProjectClasses();
  };

  const handleDeleteRow = async (id) => {
    if (reportTableData[selectedClassType].length === 1) {
      return;
    }

    await onDeleteRowFromReportProject(id);
    await onGetReportProjectClasses();
  };

  const renderContent = (item, handleChange, onSaveInDb, idx) => (
    <>
      <td className="column-1">
        <ContentEditableInput
          onCellChange={handleChange}
          text={item.title}
          rowIndex={item.id}
          isChangeText={true}
          onSaveInDb={onSaveInDb}
          idx={idx}
          cellClassName="w-100"
        />
      </td>
      <td className="column-2">
        <ContentEditableInput
          onCellChange={handleChange}
          text={item.body}
          rowIndex={item.id}
          isChangeDesc={true}
          onSaveInDb={onSaveInDb}
          idx={idx}
          cellClassName="w-100"
        />
      </td>
    </>
  );

  return (
    <>
      <div className="keyword-section border">
        <div className="d-flex justify-content-between align-items-center">
          <div className="class-radio-group p-0 fs-14 pl-2">
            {["cpc","ipc","us"].map((type, index) => (
              <div
                className="form-check form-check-inline cursor-pointer me-5"
                key={index}
              >
                <input
                  className="me-1"
                  type="radio"
                  name="searchType"
                  id={type}
                  value={type}
                  checked={selectedClassType === type}
                  onChange={handleChange}
                />
                <label
                  className="form-check-label p-0 cursor-pointer fs-16"
                  htmlFor={type}
                >
                  {type.toUpperCase()}
                </label>
              </div>
            ))}
          </div>
          <button
            className="keyword-generator fs-14 px-3"
            onClick={() => setIsOpen(true)}
          >
            Add Class
          </button>
        </div>
        <SortableTable
          items={items}
          onSortEnd={onSortEnd}
          renderContent={renderContent}
          modal={ReportClassesModal}
          isModalOpen={isOpen}
          setModalOpen={setIsOpen}
          onChange={handleInputChange}
          onSaveInDb={handleSaveInDb}
          workspaceId={3}
          onDelete={handleDeleteRow}
          onAddRow={handleAddRow}
          headers={["Class", "Definition"]}
        />
      </div>
    </>
  );
};

export default ClassesSection;
