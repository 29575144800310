import React, { useState } from "react";
import { ROLE_ADMIN, ROLE_GENERAL_USER } from "../../../../common/user-roles";
import AdminNewAccessPage from "./admin-new-access-page";
import GeneralUserUsers from "./general-user-users";
import useConfirm from "../../../../context/ConfirmDialogProvider";
import { ACCESS_VIEWER, ROLE_PARTICIPANT } from "../../../../common/user-roles";
import { Message, toaster } from "rsuite";

const UsersPage = (props) => {
  const {
    userRole,
    projectUsers,
    onSetProjectReport,
    onUpdateRoleOfUserInProject,
    onRemoveAccessOfUserFromProject,
    existingOrganizationalUsers,
    onAddUsersToProject,
  } = props;
  const confirm = useConfirm();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleChangeAccess = async (value, userId) => {
    const choice = await confirm({
      title: "Change the Access",
      description: "Are you sure you want to modify the access ?",
      confirmBtnLabel: "Confirm",
    });

    if (choice) {
      await onUpdateRoleOfUserInProject(userId, {
        newAccess: value,
        newRole: "",
      });

      const updatedUsers =
        projectUsers &&
        projectUsers.length > 0 &&
        projectUsers.map((user) => {
          if (user.id === userId) {
            // Update the role for the matching id
            return { ...user, access: value };
          }
          return user;
        });

      onSetProjectReport({
        projectUsers: [...updatedUsers],
      });
    }
  };

  const handleRemoveAccess = async (id) => {
    const choice = await confirm({
      title: "Remove the Access",
      description: "Are you sure you want to remove the access ?",
      confirmBtnLabel: "Confirm",
    });

    if (choice) {
      await onRemoveAccessOfUserFromProject(id);

      const updatedUsers =
        projectUsers &&
        projectUsers.length > 0 &&
        projectUsers.filter((user) => user.id !== id);

      onSetProjectReport({
        projectUsers: [...updatedUsers],
      });
    }
  };

  const handleChangeRole = async (value, userId) => {
    const choice = await confirm({
      title: "Change the Role",
      description: "Are you sure you want to modify the role ?",
      confirmBtnLabel: "Confirm",
    });

    if (choice) {
      await onUpdateRoleOfUserInProject(userId, {
        newRole: value,
        newAccess: "",
      });

      const updatedUsers =
        projectUsers &&
        projectUsers.length > 0 &&
        projectUsers.map((user) => {
          if (user.id === userId) {
            // Update the role for the matching id
            return { ...user, role: value };
          }
          return user;
        });

      onSetProjectReport({
        projectUsers: [...updatedUsers],
      });
    }
  };

  const handleAddUserToProject = async () => {
    const projectId = sessionStorage.getItem("projectId");
    const idsOfAddedUsers = await onAddUsersToProject({
      userEmails: selectedUsers,
      projectId: projectId,
      role: ROLE_PARTICIPANT,
      access: ACCESS_VIEWER,
    });
    if (idsOfAddedUsers.length === 0) {
      toaster.push(
        <Message type="error">Users is already added to project</Message>
      );
      setSelectedUsers([]);
      return;
    }
    // Add users to projectUsers
    let usersToAdd = [...projectUsers];
    selectedUsers.forEach((email, index) => {
      let user = existingOrganizationalUsers.find(
        (u) => u.email.toString() === email
      );
      if (user) {
        usersToAdd.push({
          userId: parseInt(user.id), // userId as integer
          email: user.email,
          userName: user.name, // 'name' from existingOrganizationalUsers as 'userName'
          role: ROLE_PARTICIPANT, // defined how 'role' is determined using a default value
          id: parseInt(idsOfAddedUsers[index]), // want to use the same id in projectUsers
          access: ACCESS_VIEWER, // defined how 'access' is determined using a default value
        });
      }
    });

    onSetProjectReport({
      projectUsers: [...usersToAdd],
    });
    setSelectedUsers([]);
    toaster.push(
      <Message type="success">Users added to project successfully</Message>
    );
  };
  switch (userRole) {
    case ROLE_ADMIN:
      return (
        <AdminNewAccessPage
          {...props}
          onChangeAccess={handleChangeAccess}
          onRemoveAccess={handleRemoveAccess}
          onChangeRole={handleChangeRole}
          onSelectedUsers={setSelectedUsers}
          selectedUsers={selectedUsers}
          onAddUserToProject={handleAddUserToProject}
        />
      );
    case ROLE_GENERAL_USER:
      return (
        <GeneralUserUsers
          {...props}
          onChangeAccess={handleChangeAccess}
          onRemoveAccess={handleRemoveAccess}
          onChangeRole={handleChangeRole}
          onSelectedUsers={setSelectedUsers}
          selectedUsers={selectedUsers}
          onAddUserToProject={handleAddUserToProject}
        />
      );
    default:
      return null;
  }
};

export default UsersPage;
