import { NoDashboardData } from "../../../assets/images/report";
import { dashboardKeyFeaturesColor } from "../../../resources/data/highlight-colors";
import { Block } from "../../../ui-components";

const KeyFeaturesCount = (props) => {
  const { keyFeaturesList, keyFeature: { data = [] } = {} } = props;

  const getKeyFeaturesCount = (id) => {
    const mappedKeyFeatures = data.filter((d) => d.keyFeature === id) || [];
    if (mappedKeyFeatures[0] && mappedKeyFeatures[0].count) {
      return mappedKeyFeatures[0].count < 10
        ? `0${mappedKeyFeatures[0].count}`
        : mappedKeyFeatures[0].count;
    } else {
      return "00";
    }
  };

  return (
    <Block
      className={`key-features-counts-wrapper mt-2 ${
        keyFeaturesList.filter((i) => i.body).length === 0
          ? "keyfeatures-nodatawrapper"
          : ""
      }`}
    >
      <Block className="key-feature-results py-2 fw-bold text-black">
        {" "}
        {keyFeaturesList.filter((i) => i.body).length === 0
          ? ""
          : "Results for Each Key Feature "}{" "}
      </Block>
      <Block
        className={`key-features ${
          keyFeaturesList.filter((i) => i.body).length === 0
            ? "keyfeatures-nodata text-center"
            : ""
        }`}
      >
        {keyFeaturesList.filter((i) => i.body).length === 0 ? (
          <>
            <img
              src={NoDashboardData}
              alt="NoDashboardData"
              className="nodata-image"
            />
            <Block className="key-data-not-availabe pt-2">
              {" "}
              Data Not Available!{" "}
            </Block>
          </>
        ) : (
          keyFeaturesList
            .filter((i) => i.body)
            .map((item, key) => (
              <Block
                key={item.id}
                className="key-feature-block"
                style={{ backgroundColor: dashboardKeyFeaturesColor[key] }}
              >
                <Block className="key-feature-count">
                  {getKeyFeaturesCount(item.id)}
                </Block>
                <Block className="key-feature-name">
                  Key Features {key + 1}
                </Block>
              </Block>
            ))
        )}
      </Block>
    </Block>
  );
};

export default KeyFeaturesCount;
//{keyFeaturesList.filter((i)=>i.body).length === 0 ? "" : "Results for Each Key Feature " }
