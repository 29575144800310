/* eslint-disable no-alert */
import RWireButton from "../rwire-ui/rwire-button/rwire-button";
import reRunIcon from "../../images/refresh.svg";
import exportIcon from "../../images/export.svg";
import combineIcon from "../../images/combine-icon.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Actionbuttons = (props) => {
  const { t } = useTranslation();

  const {
    selectedRows,
    onFetchHistory,
    onUpdateQueryCount,
    data,
    onExportHistory,
    setSelectedField,
    onSetSelectedCombineQuery,
    onSetSelectedCombineQueryId,
    selectedItemList,
  } = props;

  const [validationMessage, setValidationMessage] = useState("");
  const [updateValidationMessage, setUpdateValidationMessage] = useState("");
  const handleUpdateCount = async () => {
    if (selectedRows.length < 1) {
      setUpdateValidationMessage("Please select string for update count");
      setTimeout(() => {
        setUpdateValidationMessage("");
      }, 3000);
      return;
    }
    await selectedRows.map(async (item, index) => {
      const filterItem = data.filter((i) => `${i.id}` === `${item}`);

      await onUpdateQueryCount(
        item,
        filterItem[0].query,
        filterItem[0].is_combined,
        filterItem[0],
        filterItem[0].includeFields
      ).then(async () => {
        if (selectedRows.length - 1 === index) {
          await onFetchHistory();
        }
      });
    });
  };

  const handleExport = () => {
    onExportHistory();
  };
  const handleCombineClick = () => {
    if (selectedRows.length < 1) {
      setValidationMessage("Please select two or more strings for combine");
      setTimeout(() => {
        setValidationMessage("");
      }, 3000);
    } else if (
      selectedItemList.some(
        (obj) => obj.includeFields && obj.includeFields.length > 0
      )
    ) {
      alert(
        "Error: Invalid Combination\nSpecial strings that include families or citations cannot be combined."
      );
    } else {
      onSetSelectedCombineQuery("");
      onSetSelectedCombineQueryId(null);
      setValidationMessage("");
      setSelectedField("Combine");
    }
  };

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex">
        <RWireButton
          cNameDiv="search-query"
          buttonCName="input-button-text-form big-width"
          name={t("history-update-count")}
          buttonImg={reRunIcon}
          onClick={handleUpdateCount}
        />
        {updateValidationMessage && (
          <p className="updatevalidation-msg mt-1 pr-2">
            {updateValidationMessage}
          </p>
        )}
      </div>
      <div className="d-flex">
        <div className="d-flex">
          {validationMessage && (
            <p className="validation-msg mt-1 pr-2">{validationMessage}</p>
          )}
          <RWireButton
            cNameDiv="search-query"
            buttonCName="input-button-text-form"
            name={t("history-update-combine")}
            buttonImg={combineIcon}
            onClick={handleCombineClick}
          />
        </div>
        <div className="ms-2">
          <RWireButton
            cNameDiv="search-query"
            buttonCName="input-button-text-form"
            name={t("history-update-export")}
            buttonImg={exportIcon}
            onClick={handleExport}
          />
        </div>
      </div>
    </div>
  );
};

export default Actionbuttons;
