import React, { useEffect, useRef } from "react";
import { Checkbox, CheckboxGroup } from "rsuite";
import FilterIcon from "../../../assets/images/FilterIcon.png";
import RWireButton from "../../../components/rwire-ui/rwire-button/rwire-button";
import { FiChevronDown } from "react-icons/fi";

const CitedByFiter = (props) => {
  const {
    citedByFilterValue,
    handleChangeCitedBy,
    citedByMapping,
    citedByFilterToggle,
    setCitedByFilterToggle,
    citations,
    forward_citations,
  } = props;

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const hasMultipleCheckboxes =
    Object.keys(citedByMapping).filter(
      (key) =>
        citations.some((bcp) => bcp.CTBY === key) ||
        forward_citations.some((bcp) => bcp.CTBY === key)
    ).length > 1;

  useEffect(() => {
    const handleScroll = () => {
      const dropdownMenu = dropdownRef.current;
      if (
        dropdownMenu &&
        window.scrollY > buttonRef.current.getBoundingClientRect().bottom &&
        window.scrollY > dropdownMenu.getBoundingClientRect().top
      ) {
        setCitedByFilterToggle(false);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonRef, dropdownRef]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCitedByFilterToggle(false);
      }
    };
    document.body.addEventListener("click", handleOutsideClick);
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [setCitedByFilterToggle]);

  return (
    <th className="citedby__filter__btn" ref={dropdownRef}>
      <div
        className="d-flex p-0 justify-content-between cursor-pointer"
        onClick={() => setCitedByFilterToggle(!citedByFilterToggle)}
        ref={buttonRef}
      >
        <RWireButton cNameDiv="p-0 citations_filter" buttonCName="text-left">
          {hasMultipleCheckboxes && (
            <img src={FilterIcon} alt="Filter Icon" className="mr-1" />
          )}
          Cited By
        </RWireButton>
        {hasMultipleCheckboxes && (
          <FiChevronDown style={{ fontSize: "20px", color: "#000" }} />
        )}
      </div>
      {citedByFilterToggle && hasMultipleCheckboxes && (
        <CheckboxGroup
          className="p-0"
          value={citedByFilterValue}
          onChange={handleChangeCitedBy}
        >
          <div className="citedby-filter-modal">
            {Object.entries(citedByMapping).map(([key, value]) => {
              const hasData =
                citations.some((bcp) => bcp.CTBY === key) ||
                forward_citations.some((bcp) => bcp.CTBY === key);

              if (hasData) {
                return (
                  <div
                    className="citedby__filter__checkbox text-nowrap"
                    key={key}
                  >
                    <Checkbox value={key}>{value}</Checkbox>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </CheckboxGroup>
      )}
    </th>
  );
};

export default CitedByFiter;
