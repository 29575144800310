import React, { useState, useEffect } from "react";
import { Checkbox, Input, InputGroup } from "rsuite";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";
import {
  findMatchingWords,
  findMatchingWordsFromResults,
} from "../../action/highlight";

export const containsSpecialCharacters = (str) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /[!@#$%^&*()_+\-=\[\]{}'"\\:<>\/?~]/g;
  return regex.test(str);
};

const HighlightModal = (props) => {
  const {
    isExact,
    onSetFilter,
    highlightedWords = [],
    onSetHighlight,
    highlightColor,
    highlightText,
    result,
    detailsData,
  } = props;

  const [validationMessage, setValidationMessage] = useState("");
  const [storedHighlightedWords, setStoredHighlightedWords] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem("highlightedWords");
    if (storedData) {
      setStoredHighlightedWords(JSON.parse(storedData));
    }
  }, []);

  // Update localStorage whenever highlightedWords changes
  useEffect(() => {
    localStorage.setItem("highlightedWords", JSON.stringify(highlightedWords));
    setStoredHighlightedWords(highlightedWords);
  }, [highlightedWords]);

  const highlightData = storedHighlightedWords.filter((i) => !i.isQueryWord);

  const highlightChange = (e) => {
    if (!containsSpecialCharacters(e)) {
      onSetHighlight({ highlightText: e });
      setValidationMessage("");
    } else {
      setValidationMessage(
        "`~!@#$%&^*_=-[]{}()'+?/:<> Symbols are not permitted"
      );
      setTimeout(() => {
        setValidationMessage("");
      }, 4000);
    }
  };

  const highlightClear = () => {
    const filteredHighlight = storedHighlightedWords.filter(
      (i) => i.isQueryWord
    );
    onSetHighlight({
      highlightedWords: filteredHighlight,
      highlightText: "",
    });
  };

  const highlightApply = async () => {
    const searchWords = highlightText
      ? highlightText
          .trim()
          // Split by whitespace and common punctuation marks
          .split(/\s*[,|;" "\s*]\s*/)
          .filter((word) => word)
      : [];

    const filteredHighlight = storedHighlightedWords.filter(
      (i) => i.isQueryWord
    );
    let addNewWord = [];

    let matchedWord = findMatchingWordsFromResults(
      [...result, detailsData],
      searchWords,
      isExact
    );

    searchWords.forEach((item, key) => {
      const smallCaseItem = item.toLowerCase();
      let colorCode = "";
      if (matchedWord.length > 0 && matchedWord.includes(smallCaseItem)) {
        const existingHighlight = storedHighlightedWords.find(
          (word) => word.word.toLowerCase() === smallCaseItem
        );
        colorCode = existingHighlight
          ? existingHighlight.color
          : highlightColor[key + 1];
      }

      const newHighlightObj = {
        word: item,
        color: colorCode,
        isQueryWord: false,
      };
      addNewWord.push(newHighlightObj);
    });
    const newHighlightedWords = [...filteredHighlight, ...addNewWord];
    onSetHighlight({
      highlightedWords: newHighlightedWords,
      highlightText: searchWords.join(","),
    });
    addNewWord = [];
  };

  const handleKeyPress = (event) => {
    const alertData = "`~!@#$%&^*_=-[]{}()'+?/:<> Symbols are not permitted";
    if (
      event.key === "$" ||
      event.key === "^" ||
      event.key === "(" ||
      event.key === ")" ||
      event.key === "+" ||
      event.key === "?" ||
      event.key === "/" ||
      event.key === "*" ||
      event.key === "." ||
      event.key === '"' ||
      event.key === "'" ||
      event.key === "]" ||
      event.key === "[" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "%" ||
      event.key === "&" ||
      event.key === "-" ||
      event.key === "_" ||
      event.key === "=" ||
      event.key === ":" ||
      event.key === ">" ||
      event.key === "<" ||
      event.key === "{" ||
      event.key === "}" ||
      event.key === "`" ||
      event.key === "~"
    ) {
      event.preventDefault();
      // eslint-disable-next-line no-console
      console.log(alertData);
      setValidationMessage(alertData); // Set a validation message to inform the user about the restriction.
      setTimeout(() => {
        setValidationMessage("");
      }, 4000);
    }
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      if (!highlightText) {
        return false;
      }

      highlightApply();
    }

    return;
  };

  const handleRemove = (e) => {
    const arr = storedHighlightedWords.filter((i) => i.word !== e);
    const remainingHighlightedWords = highlightData
      .filter((i) => i.word !== e)
      .map((w) => w.word);
    onSetHighlight({
      highlightedWords: arr,
      highlightText: remainingHighlightedWords.join(","),
    });
  };

  const handleChange = () => {
    onSetFilter({ isExact: !isExact });
  };

  return (
    <div>
      <div className="">
        <div>
          {validationMessage && (
            <div
              className="validation-message-hilight"
              style={{ color: "red" }}>
              {validationMessage}
            </div>
          )}
        </div>
        <div className="d-flex highlight-keyword mt-2">
          <p className="text-left w-100">Highlight keywords</p>
          <div
            onClick={() => {
              onSetHighlight({ isVisibleCustomiseHighlightModal: true });
            }}
            className="d-flex">
            <p className="text-right mt-0 cursor-pointer">Customize </p>
            <img
              src={RWIRE_IMAGES.RwireEditIcon}
              className="ml-2 cursor-pointer"
              alt="cutomize_img"
            />
          </div>
        </div>

        <div className="highlight-modal-input mt-2">
          <InputGroup inside>
            <Input
              placeholder="Add a keywords"
              value={highlightText}
              onKeyPress={handleKeyPress}
              onChange={highlightChange}
              onKeyDown={handleEnter}
            />
          </InputGroup>
        </div>

        <div className="d-flex flex-wrap">
          {highlightData.map((item, index) => {
            return highlightText === "" ? (
              highlightClear()
            ) : (
              <button
                className={`item-color  mx-2 mt-2 px-1 `}
                style={{
                  backgroundColor: item.color,
                }}
                key={index}>
                {item.word}
                <img
                  alt=""
                  src={RWIRE_IMAGES.RwireCloseIcon}
                  className="cross-img"
                  onClick={() => handleRemove(item.word)}
                />
              </button>
            );
          })}
        </div>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <div className="d-flex">
          <Checkbox onChange={handleChange} checked={isExact}></Checkbox>
          <p className="syntax-check text-box highlight-checkbox pb-0 highlight-top">
            Exatch Match
          </p>
        </div>
        <div className="d-flex justify-content-between">
          <h6
            className="pr-4 menu highlight-top"
            style={{ color: "#000000", fontSize: "14px" }}
            onClick={highlightClear}>
            Clear All
          </h6>
          <button className="apply-highlight-button" onClick={highlightApply}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default HighlightModal;
