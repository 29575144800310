import React, { useState, useEffect } from "react";
import ContentEditable from "../content-editable";
import RWireButton from "../../rwire-ui/rwire-button/rwire-button";
import RWIRE_IMAGES from "../common-functions/rwire-images";
import { useStore } from "react-redux";
import { BiEdit } from "react-icons/bi";
import "../../../assets/css/search-result-page.scss";
import "../../../../src/pages/rwire-search-result/style.scss";
import { clearFielded } from "../../../common/clear-filded";
import { cleanQuery } from "../../../common/query-functions";

const RWirePatentsSearch = (props) => {
  const {
    onPerformSearch,
    validationQuery,
    syntaxqueryError,
    syntaxqueryCorrect,
    onSetApp,
    isIncremental,
    onOpenModal,
    displayQueryIncremental = [],
    onSetIncrementalPart,
    queryFields: fieldData = [],
    onSetAllField,
    searchedTab,
    tabWiseSearchQuery,
    isQuickView = false,
    selectedRows,
  } = props;

  const store = useStore();
  const [Query, setQuery] = useState("");
  const {
    app: { searchedVisibleQuery },
  } = store.getState();

  useEffect(() => {
    setQuery(searchedVisibleQuery);
  }, [searchedVisibleQuery]);

  const handleChange = (value) => {
    let str = value;
    // str = str.replace(/(<([^>]+)>)/gi, "");
    setQuery(str);
    onSetApp({ isUpdateQuery: true });
  };

  const handleSearch = () => {
    if (Query) {
      let newQuery =
        isIncremental && value !== "()"
          ? cleanQuery(`(${Query}) NOT ${value}`)
          : Query;
      onSetApp({
        searchQuery: newQuery,
        isSearchFromIncremental: isIncremental,
        searchedVisibleQuery: newQuery,
        selectedIncludes: [],
      });
      onSetIncrementalPart(false);
      onPerformSearch(newQuery, { isResetHighlight: true }).then((data) => {
        if (data) {
          const clearData = clearFielded(fieldData);
          onSetAllField({
            queryFields: clearData,
            tabWiseSearchQuery: {
              ...tabWiseSearchQuery,
              [searchedTab]: newQuery,
            },
          });
        } else {
          // eslint-disable-next-line no-console
          console.log(props.error);
        }
      });
    }
  };

  const handleClick = () => {
    handleSearch();
  };

  const handleSearchCitations = async () => {
    if (Query) {
      if (selectedRows && selectedRows.length < 1) {
        // eslint-disable-next-line no-alert
        alert(
          "Please select at least one publication number to search citations"
        );
        return;
      }
      let newQuery = `BOTH=(${selectedRows.join(" OR ")})`;

      await onPerformSearch(`BOTH=(${selectedRows.join(" OR ")})`, {
        isBothCitations: true,
        isResetHighlight: true,
      }).then((data) => {
        if (data) {
          const clearData = clearFielded(fieldData);
          onSetAllField({
            queryFields: clearData,
            tabWiseSearchQuery: {
              ...tabWiseSearchQuery,
              [searchedTab]: newQuery,
            },
          });
          onSetApp({ selectedIncludes: ["BCP, FCP"] });
        } else {
          // eslint-disable-next-line no-console
          console.log(props.error);
        }
      });
      onSetApp({
        searchQuery: newQuery,
        isSearchFromIncremental: isIncremental,
        searchedVisibleQuery: newQuery,
      });
    }
  };
  const queryValidation = () => {
    validationQuery(Query);
  };

  let queryValueArray = [];
  displayQueryIncremental &&
    displayQueryIncremental.length &&
    displayQueryIncremental.map((item) => queryValueArray.push(item.query));

  const value = `(${queryValueArray.join(" OR ")})`;

  return (
    <div className={`patent-view-search-table ${isQuickView ? "m-0" : ""} `}>
      <div className="search-section-patent align-items-stretch">
        <div
          className={`content-editable ${isIncremental ? "isOpen" : ""} ${
            !isIncremental ? "hide-query" : ""
          }`}
        >
          {" "}
          {isIncremental && <div className="query_heading">Current Query</div>}
          <ContentEditable
            value={Query}
            height="100px"
            width={isIncremental ? "40%" : "90%"}
            onChange={handleChange}
            isShowDummyWrapper={true}
          />
        </div>
        {isIncremental && (
          <div className="previous_queery">
            <div className="query_heading" onClick={onOpenModal}>
              Previous Query
              <div className="edit-button">
                <BiEdit
                  alt="edit"
                  className="img-fluid"
                  width="30"
                  height="30"
                />
              </div>
            </div>
            <ContentEditable
              value={value}
              height="100px"
              width={isIncremental ? "40%" : "90%"}
              onChange={() => {}}
              isReadOnly={true}
            />
          </div>
        )}

        <div className="patent-button-search d-flex flex-column justify-content-end gap-1 ps-2">
          <RWireButton
            cNameDiv="search-query"
            buttonCName="input-button-text-form"
            name="Search"
            buttonImg={RWIRE_IMAGES.RwireSearchBlackIcon}
            onClick={handleClick}
          />
          {!isQuickView && (
            <RWireButton
              cNameDiv="search-query"
              buttonCName="input-button-text-form blue-primary-button-color"
              name="Citations"
              onClick={handleSearchCitations}
            />
          )}
        </div>
      </div>
      {Query ? (
        <div className="text-area-syntax mt-1 mx-2">
          {syntaxqueryCorrect ? (
            <div style={{ color: "green" }}>{syntaxqueryCorrect}</div>
          ) : (
            <div style={{ color: "#f00" }}>{syntaxqueryError}</div>
          )}
          <div
            className={`syntax-check text-box ${
              isQuickView ? "check-box" : "result-check-box"
            }`}
            onClick={queryValidation}
          >
            Check Syntax
            <input type="checkbox" className="mx-2" checked />
          </div>
        </div>
      ) : (
        <div className="text-area-syntax text-area-syntax-opcaity  mt-2 mx-2">
          {syntaxqueryCorrect ? (
            <div style={{ color: "green" }}>{syntaxqueryCorrect}</div>
          ) : (
            <div style={{ color: "#f00" }}>{syntaxqueryError}</div>
          )}
          <div
            className={`syntax-check text-box ${
              isQuickView ? "check-box" : "result-check-box"
            }`}
          >
            Check Syntax
            <input type="checkbox" className="mx-2" checked disabled />
          </div>
        </div>
      )}
    </div>
  );
};

export default RWirePatentsSearch;
