import { React, useState } from "react";
import FirstPart from "./first-part";
import ContactUs from "./contactus-part";
import { leftFeatures } from "../../resources/data/faq-qa-data";
import SecondPart from "./second-part";
import "./style.scss";

const RwireFaq = () => {
  const [activeIndex, setActiveIndex] = useState();
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="faq-page">
      <FirstPart />
      <SecondPart
        leftFeatures={leftFeatures}
        activeIndex={activeIndex}
        handleToggle={handleToggle}
      />
      <div className="white-bg"></div>
      <ContactUs />
    </div>
  );
};

export default RwireFaq;
