import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CloseIcon from "@rsuite/icons/Close";

const SortableFields = ({
  selectedFields,
  onDragEnd,
  handleRemoveItem,
  fieldNameFromShortCodeExport,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="selectedFields">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {selectedFields.map((item, index) => (
              <Draggable key={item} draggableId={item} index={index}>
                {(provided) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="p-1 ml-2 d-flex justify-content-between align-items-center"
                    >
                      {fieldNameFromShortCodeExport(item)}
                      {item !== "PN_B" && (
                        <CloseIcon
                          className="mr-2 cursor-pointer"
                          onClick={() => handleRemoveItem(item)}
                        />
                      )}
                    </div>
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder} {/* Placeholder to maintain layout */}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableFields;
