import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./style.scss";

const SlidingSidePanel = ({
  isOpen,
  onClose,
  children,
  className = "",
  isStatic = true,
  overlayClass = "",
  id = "",
  isSmartReaderOpen,
}) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto"; // Clean up on unmount
    };
  }, [isOpen]);

  if (!isOpen) {
    return null; // Render nothing if not open
  }

  return ReactDOM.createPortal(
    <div
      className={`add-comment-modal-overlay ${
        isSmartReaderOpen ? "smart-reader-open" : ""
      } ${className} ${overlayClass} ${isOpen ? "open" : ""}`}
      aria-hidden={!isOpen}
    >
      <div
        className={`add-comment-modal-body ${
          isSmartReaderOpen ? "smart-reader-open" : ""
        } ${className} ${isOpen ? "open" : ""}`}
        id={id}
      >
        {children}
      </div>
    </div>,
    document.getElementById("rwire-custom-modal-root")
  );
};

export default SlidingSidePanel;
