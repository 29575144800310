/* eslint-disable no-console */
import axios from "axios";
import { fetch } from "../../service/fetch";

export const setReportCollab = (payload) => ({
  type: "COLLAB_SET",
  payload: {
    ...payload,
  },
});

export const addCommentByRowId = (body) => async (dispatch) => {
  const {
    comment,
    workspaceElements,
    projectId,
    contentId,
    collaborationFile,
  } = body;
  try {
    const formData = new FormData();

    if (collaborationFile.length > 0) {
      collaborationFile.forEach((file) => {
        formData.append("collaborationFile[]", file);
      });
    }

    formData.append("comment", comment);
    formData.append("workspaceElements", workspaceElements);
    formData.append("projectId", projectId);
    formData.append("contentId", contentId);
    await axios.post("/api/project-collaboration", formData, {
      headers: {
        ...getHeadersWithContentType(),
      },
    });
  } catch (error) {
    console.log("error>>>", error);
  }
};
const getHeadersWithContentType = () => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    return {};
  } else {
    return {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    };
  }
};

export const getAllCommentsByRowId =
  ({ elementId, contentId }) =>
  async (dispatch) => {
    try {
      var response = {};
      const id = sessionStorage.getItem("projectId");
      response = await fetch(
        {
          url: `/api/project-collaboration/${id}/${elementId}/${contentId}`,
        },
        "GET"
      );
      dispatch(
        setReportCollab({
          rowComments: response.result.reverse(),
        })
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const updateCommentByRowId = (body) => async (dispatch) => {
  const { comment, contentId, collaborationFile } = body;
  try {
    const formData = new FormData();

    if (collaborationFile.length > 0) {
      collaborationFile.forEach((file) => {
        formData.append("collaborationFile[]", file);
      });

      formData.append("collaborationId", contentId);
      await axios.post("/api/project-collaboration-files", formData, {
        headers: {
          ...getHeadersWithContentType(),
        },
      });
    }
    if (comment) {
      await fetch(
        {
          url: `/api/project-collaboration/${contentId}`,
          body: {
            comment: comment,
          },
        },
        "PUT"
      );
    }
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const deleteCommentByCommentId =
  (commentId, onSetReportCollab, rowComments) => async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-collaboration/${commentId}`,
        },
        "DELETE"
      );
      const newData = rowComments.filter((comment) => comment.id !== commentId);
      dispatch(
        onSetReportCollab({
          rowComments: newData,
        })
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const deleteCollaborationFile =
  ({ id }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-collaboration-files/${id}`,
        },
        "DELETE"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };
