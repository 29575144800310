export const updateReportKeywordsData = (reportTableData, selectedKeywords) => {
  let endEmptyRowsCount = 0;
  let filledFromEnd = 0;

  // Identify contiguous empty rows at the end of reportTableData
  for (let i = reportTableData.length - 1; i >= 0; i--) {
    if (
      reportTableData[i].title.trim() === "" &&
      reportTableData[i].body.trim() === ""
    ) {
      endEmptyRowsCount++;
    } else {
      filledFromEnd = i + 1;
      break;
    }
  }

  let keywordIndex = 0;

  // Fill the contiguous empty rows at the end
  for (
    let i = reportTableData.length - endEmptyRowsCount;
    i < reportTableData.length;
    i++
  ) {
    if (keywordIndex < selectedKeywords.length) {
      reportTableData[i].title = selectedKeywords[keywordIndex];
      keywordIndex++;
    }
  }

  // Calculate new sortOrder
  let sortOrder =
    Math.max(...reportTableData.map((item) => parseInt(item.sortOrder)), 0) + 1;

  // Append remaining selectedKeywords as new objects in reportTableData
  for (let i = keywordIndex; i < selectedKeywords.length; i++) {
    let newItem = {
      // Generate a new id
      title: selectedKeywords[i],
      body: "",
      order: sortOrder.toString(),
    };
    reportTableData.push(newItem);
    sortOrder++;
  }

  return reportTableData.splice(filledFromEnd, reportTableData.length);
};

export const updateClassTypeTableData = (
  tableData,
  selectedRowsWithCodeAndDefination,
  classesType
) => {
  let endEmptyRowsCount = 0;
  let filledFromEnd = 0;
  const modifiedRows = [];
  const classTypeTable = tableData[classesType];

  // Check if classTypeTable is valid
  if (!Array.isArray(classTypeTable)) {
    console.error("Invalid classTypeTable:", classTypeTable);
    return modifiedRows; // Return early if classTypeTable is not valid
  }

  for (let i = classTypeTable.length - 1; i >= 0; i--) {
    if (
      classTypeTable?.[i]?.title.trim() === "" &&
      classTypeTable?.[i]?.body.trim() === ""
    ) {
      endEmptyRowsCount++;
    } else {
      filledFromEnd = i + 1;
      break;
    }
  }
  let selectedIndex = 0;

  // Fill the contiguous empty rows at the end
  for (
    let i = classTypeTable.length - endEmptyRowsCount;
    i < classTypeTable.length;
    i++
  ) {
    if (selectedIndex < selectedRowsWithCodeAndDefination.length) {
      classTypeTable[i].classes =
        selectedRowsWithCodeAndDefination[selectedIndex].code[0];
      classTypeTable[i].body =
        selectedRowsWithCodeAndDefination[selectedIndex].definition;
      modifiedRows.push(classTypeTable[i]);
      selectedIndex++;
    }
  }

  // Calculate new sortOrder
  let sortOrder =
    Math.max(
      ...classTypeTable.map((item) => parseInt(item.sortOrder || 0)),
      0
    ) + 1;

  // Append remaining selected rows as new objects in classTypeTable
  for (
    let i = selectedIndex;
    i < selectedRowsWithCodeAndDefination.length;
    i++
  ) {
    let newItem = {
      classes: selectedRowsWithCodeAndDefination[i].code[0],
      body: selectedRowsWithCodeAndDefination[i].definition,
      order: sortOrder.toString(),
    };
    modifiedRows.push(newItem);
    sortOrder++;
  }

  return modifiedRows;
};
