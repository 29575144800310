import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { HighlightComponent } from "../../common/highlight";
import { setDetails, setFilterFields } from "../../action/result-table";

const mapStateToProps = ({
  resultTable: { isExact, queryRegex },
  highlight,
}) => ({
  ...highlight,
  isExact,
  queryRegex,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      onSetDetails: setDetails,
      onSetFilter: setFilterFields,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(HighlightComponent);
