import React from "react";

const Image = ({
  className = "",
  src,
  alt = "",
  title,
  onClick,
  style,
  width,
  height,
}) => {
  return (
    <img
      src={src}
      alt={alt}
      title={title}
      className={className}
      onClick={onClick}
      style={style}
      width={width}
      height={height}
    />
  );
};

export default Image;
