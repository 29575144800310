/* eslint-disable */
import { fetch } from "../service/fetch";
import { fieldNameFromShortCodeExport } from "../common/field-map";
import { getSelectedFilters, tempSelected } from "./aggregationFunction";

export const setExport = (payload) => ({
  type: "EXPORT_SET",
  payload: {
    ...payload,
  },
});

export const setSelectedField = (payload) => ({
  type: "EXPORT_SET",
  payload: {
    ...payload,
  },
});

export const setSortedRecords = (payload) => ({
  type: "EXPORT_SET",
  payload: {
    ...payload,
  },
});

export const createExportTemplate = (templateData) => async (dispatch) => {
  console.log("templateData", templateData);
  try {
    const response = await fetch({
      url: "/api/export-template",
      method: "POST",
      body: templateData,
    });
    return response;
  } catch (error) {
    return "Error";
  }
};

export const updateExportTemplate =
  ({ id, body }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/export-template/${id}`,
          body,
        },
        "PUT"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const getExportTemplates = (page) => async (dispatch) => {
  try {
    const response = await fetch(
      {
        url: `/api/export-template/${page}`,
      },
      "GET"
    );
    // console.log("GET request response:", response.result);
    return response.result;
  } catch (error) {
    return "Error";
  }
};

export const setDefaultExportTemplate = (templateData) => async (dispatch) => {
  try {
    const response = await fetch({
      url: "/api/export-template/default",
      method: "POST",
      body: templateData,
    });
    return response;
  } catch (error) {
    return "Error";
  }
};

export const DeleteExportTemplate = (id) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/export-template/${id}`,
      },
      "DELETE"
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const exportFromExist = ({ parentId, emails }) => {
  try {
    const response = fetch({
      url: "/api/export-request/from-exist",
      body: {
        parentId: parentId,
        emails: emails,
      },
    });
    dispatch(
      setExport({
        isExportSent: true,
      })
    );
    return response;
  } catch (error) {
    dispatch(
      setExport({
        isExportMailError: error.message,
      })
    );

    return "Error";
  }
};

export const exportMailRecord =
  ({
    emails,
    subject,
    fileName,
    message,
    isDownload = false,
    searchBody,
    searchFields,
  }) =>
  async (dispatch, getState) => {
    const {
      exportField: { selectedFields, selectedType: fileType = "csv" },
      resultTable: { selectedRows },
      app: { searchQuery, collapsableFields, selectedIncludes },
    } = getState();
    let fieldsExportString = {};
    let exportQueryJson = {};

    if (!searchBody) {
      selectedFields.map((ev) => {
        fieldsExportString[ev] = fieldNameFromShortCodeExport(ev);
      });

      fieldsExportString = JSON.stringify(fieldsExportString);
      let filters = getSelectedFilters(getState().resultTable);

      exportQueryJson = JSON.stringify({
        query: searchQuery,
        collapsableFields: collapsableFields,
        filters: filters,
        selectedRows: selectedRows,
        selectedIncludes:
          selectedIncludes && selectedIncludes.length > 0
            ? selectedIncludes
            : null,
        isNumberWithIncludeSearch:
          selectedIncludes && selectedIncludes.length > 0 ? true : false,
      });
    } else {
      fieldsExportString = searchFields;
      exportQueryJson = searchBody;
    }
    try {
      const response = await fetch({
        url: "/api/export-request",
        body: {
          queryBody: exportQueryJson,
          exportFields: fieldsExportString,
          fileType,
          ...{ emails, subject, fileName, message, isDownload },
        },
      });
      dispatch(
        setExport({
          isExportSent: true,
        })
      );
      return response;
    } catch (error) {
      dispatch(
        setExport({
          isExportMailError: error.message,
        })
      );

      return "Error";
    }
  };
