import React from "react";
import SortableTable from "./single-sortable"; // Adjust the path as necessary
import { arrayMove } from "react-sortable-hoc";
import { updateSortOrder } from "../../ui-components/functions/notes/utils";

// Assignees Component
const Assignees = ({
  reportTableData,
  onSetReportAssignee,
  onUpdateAssigneeSortOrder,
  onUpdateReportProjectAssigneeRow,
  onAddRowToreportProject,
  onGetReportProjectAssignees,
  onDeleteRowFromReportProject,
}) => {
  const handleSortEnd = async ({ oldIndex, newIndex }) => {
    onSetReportAssignee({
      reportTableData: arrayMove(reportTableData, oldIndex, newIndex),
    });
    const bodyData = updateSortOrder(reportTableData, oldIndex, newIndex);
    await onUpdateAssigneeSortOrder(bodyData);
  };

  const handleChange = (event, rowIndex, options) => {
    const value = event.target.value;
    const updatedTableData = reportTableData.map((i) => {
      if (i.id === rowIndex) {
        return {
          ...i,
          body: value,
        };
      }
      return {
        ...i,
      };
    });

    onSetReportAssignee({
      reportTableData: updatedTableData,
    });
  };
  const handleSaveInDb = async (value, rowIndex, idx, options) => {
    if (reportTableData[idx].body !== value) {
      await onUpdateReportProjectAssigneeRow({
        id: rowIndex,
        body: {
          body: value,
        },
      });
    }
  };

  const handleAddRow = async (rowIndex) => {
    const _id = sessionStorage.getItem("projectId");
    await onAddRowToreportProject({
      data: [
        {
          body: "",
          order: parseInt(`${rowIndex + 1}`),
        },
      ],
      projectId: parseInt(_id),
    });
    await onGetReportProjectAssignees();
  };

  const handleDeleteRow = async (id) => {
    if (reportTableData.length === 1) {
      return;
    }
    await onDeleteRowFromReportProject(id);
    await onGetReportProjectAssignees();
  };

  return (
    <>
      <p className="inventor-section-header-text fs-16 mb-2">Assignees</p>
      <SortableTable
        items={reportTableData}
        onSortEnd={handleSortEnd}
        workspaceId={4}
        onChange={handleChange}
        onSaveInDb={handleSaveInDb}
        onDelete={handleDeleteRow}
        onAddRow={handleAddRow}
      />
    </>
  );
};

export default Assignees;
