const initialState = {
  dataFrom: 0,
  classesType: "cpc",
  formData: { classes: "" },
  clasessData: [],
  selectedRows: [],
  selectedRowsWithCodeAndDefination: [],
  selectedClassType: "cpc",
  itemsPerPage: 10,
  pageSize: 10,
  page: 1,
  limit: 10,
  totalRecordsCount: 0,
  column: [
    {
      columnName: "Clasess",
      type: "text",
    },
    {
      columnName: "Definition",
      type: "text",
    },
    {
      columnName: "Add class",
      className: "add-class-btn",
      type: "button",
    },
  ],
  reportTableData: {},
  isNoResultFound: false,
};

const classes = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_CLASSES":
      return { ...initialState };
    case "GET_ALL_CLASS":
    case "CLASSES_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default classes;
