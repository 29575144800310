export const getSpecializedSearchType = (fields = []) => {
  const allFamArray = ["SF", "MF", "DF", "CF", "EF"];
  const bothCitationsArray = ["BCP", "FCP"];

  if (arraysAreEqual(fields, allFamArray)) {
    return "ALLFAM";
  } else if (arraysAreEqual(fields, bothCitationsArray)) {
    return "BOTH";
  } else {
    return fields[0];
  }
};

function arraysAreEqual(arr1, arr2) {
  if (!arr1) return false;
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

export const includeFieldsMapping = {
  BCP: "Backward Citation",
  FCP: "Forward Citation",
  BOTH: "Both Citation",
  SF: "Simple Family",
  MF: "Main Family",
  DF: "Domestic Family",
  CF: "Complete Family",
  EF: "Extended Family",
  ALLFAM: "All Families",
};
