//List of languages in which need to search
const languageCodes = ["EN"];
const dataFormatCodes = ["B", "D", "DA", "EPO", "O", "L", "T"];

const getLanguagesListFromField = (field) => {
  let fieldLanguageArray = [];
  languageCodes.forEach((item) => {
    fieldLanguageArray.push(`${field}_${item}`);
  });

  return fieldLanguageArray;
};

const getDataFormatListFromField = (field) => {
  let fieldDataFormatArray = [];
  dataFormatCodes.forEach((item) => {
    fieldDataFormatArray.push(`${field}_${item}`);
  });

  return fieldDataFormatArray;
};
const getNumberFormatListFromField = (fields) => {
  let fieldDataFormatString = [];
  dataFormatCodes.forEach((item) => {
    fields.forEach((field) => {
      fieldDataFormatString.push(`${field}_${item}.text`);
    });
  });

  return fieldDataFormatString;
};
export const multipleFieldsShortCode = {
  PAPR: getNumberFormatListFromField(["PN", "AN", "PRN"]),
  PA: getNumberFormatListFromField(["PN", "AN"]),
  PPR: getNumberFormatListFromField(["PN", "PRN"]),
  APR: getNumberFormatListFromField(["AN", "PRN"]),
  AA: getLanguagesListFromField("AA"),
  AAP: ["AAP", "AAPO", "AAPS", "AAPN"],
  AB: getLanguagesListFromField("AB"),
  AG: getLanguagesListFromField("AG"),
  AGA: getLanguagesListFromField("AGA"),
  PCL: ["IPC", "IPCD", "CPC", "CPCD"],
  ALL: [
    ...getLanguagesListFromField("TI"),
    ...getLanguagesListFromField("AB"),
    ...getLanguagesListFromField("CL"),
    ...getLanguagesListFromField("DESC"),
  ],
  AN: getNumberFormatListFromField(["AN"]),
  ANZ: getLanguagesListFromField("ANZ"),
  AO: getLanguagesListFromField("AO"),
  AP: [...getLanguagesListFromField("AP"), ...getLanguagesListFromField("API")],
  APA: getLanguagesListFromField("APA"),
  APN: [
    ...getLanguagesListFromField("APN"),
    ...getLanguagesListFromField("API"),
  ],
  APS: [
    ...getLanguagesListFromField("APS"),
    ...getLanguagesListFromField("API"),
  ],
  CLA: [...getLanguagesListFromField("CL"), ...getLanguagesListFromField("AB")],
  CAA: getLanguagesListFromField("CAA"),
  CAN: getLanguagesListFromField("CAN"),
  CAS: getLanguagesListFromField("CAS"),
  CD: [
    ...getLanguagesListFromField("CL"),
    ...getLanguagesListFromField("DESC"),
  ],
  CL: getLanguagesListFromField("CL"),
  CR: getLanguagesListFromField("CR"),
  CRA: getLanguagesListFromField("CRA"),
  CPC: ["CPC", "CPCD"],
  DC: ["DC", "DCD"],
  DCL: getLanguagesListFromField("DCL"),
  DESC: getLanguagesListFromField("DESC"),
  EX: ["AEX", "PEX"],
  ECL: ["ECL", "ECLD"],
  CA: getLanguagesListFromField("CA"),
  FCL: getLanguagesListFromField("FCL"),
  ICL: getLanguagesListFromField("ICL"),
  IN: getLanguagesListFromField("IN"),
  INA: getLanguagesListFromField("INA"),
  INF: getLanguagesListFromField("INF"),
  IPC: ["IPC", "IPCD"],
  LC: ["LCM", "LCF"],
  PCT: getDataFormatListFromField("PCT"),
  PCTN: getDataFormatListFromField("PCTN"),
  PN: getNumberFormatListFromField(["PN"]),
  PRN: getNumberFormatListFromField(["PRN"]),
  SS: [
    ...getLanguagesListFromField("TI"),
    ...getLanguagesListFromField("AB"),
    ...getLanguagesListFromField("CL"),
    "PN_B",
    "AN_B",
  ],
  TA: [...getLanguagesListFromField("TI"), ...getLanguagesListFromField("AB")],
  TAC: [
    ...getLanguagesListFromField("TI"),
    ...getLanguagesListFromField("AB"),
    ...getLanguagesListFromField("CL"),
  ],
  TC: [...getLanguagesListFromField("TI"), ...getLanguagesListFromField("CL")],
  TI: getLanguagesListFromField("TI"),
  USC: ["USM", "USD", "USF", "USFD"],
};
