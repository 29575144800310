import React from 'react';
import "./style.scss";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";


const FirstPart = () => (
  <div className="first_part position-relative text-center">
    <img className="faq_banner img-fluid" alt="" src={RWIRE_IMAGES.RwireFaqBanner} />
    <div className="position-absolute top-50 start-50 translate-middle p-3">
      <div className="first_heading h3">Frequently Asked Questions</div>
      <div className="first_para">
        At Researchwire, we are dedicated to transforming how innovators, researchers, and businesses access and utilize patent information. Our mission is to provide a comprehensive, user-friendly patent database that empowers you to stay ahead in a competitive landscape.
      </div>
    </div>
  </div>
);

export default FirstPart