import React from "react";
import {
  RwireReportAssignee,
  RwireReportInventor,
} from "../../container/report";

function InventorAssigneeSection() {
  return (
    <div className="keyword-section border assignees-section">
      <RwireReportInventor />
      <RwireReportAssignee />
    </div>
  );
}
export default InventorAssigneeSection;
