/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Checkbox, CheckboxGroup } from "rsuite";
import { getFileContent } from "../../common/number-search";
import { BiUpload } from "react-icons/bi";
import "../../../src/assets/css/style.scss";
import { RwireSelectInput } from "./rwire-select-input";
import "./rwire-number-search.scss";

const fields = {
  PN: "Publication",
  AN: "Application",
  PRN: "Priority",
};

const includeFields = {
  BCP: "Backward Citation",
  FCP: "Forward Citation",
  BOTH: "Both Citation",
  SF: "Simple Family",
  MF: "Main Family",
  DF: "Domestic Family",
  CF: "Complete Family",
  EF: "Extended Family",
  ALLFAM: "All Families",
};

const NumberSection = (props) => {
  const {
    onSetApp,
    selectedFieldes,
    onSetNumber,
    fileName,
    onSetQuery,
    setCheckboxError,
    checkboxError,
    selected,
    onSetSelected,
  } = props;

  const [sizeError, setSizeError] = useState("");
  const [typeError, setTypeError] = useState("");

  useEffect(() => {
    onSetNumber({ fileName: "" });
  }, []);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSizeError("");
    setTypeError("");
    document.getElementById("exceed-file-limit").innerHTML = "";

    if (file) {
      if (file.size > 2000000) {
        setSizeError("File is greater than 2Mb.");
        setTimeout(() => {
          setSizeError("");
        }, 5000);
        return;
      }

      if (file.type === "text/plain" || file.type === "text/csv") {
        onSetNumber({ fileName: file.name, isNumberSearch: false });
        let data = await getFileContent(file);
        data = data.match(/\b[a-zA-Z]*\s*\d+\s*[a-zA-Z]*\d*\b/gm);
        data = data
          .join(",")
          .replaceAll("\n", ",")
          .replaceAll("\r", "")
          .replaceAll(/,+/g, ",");
        onSetQuery(data);
      } else {
        setTypeError("File is not supported.");
        setTimeout(() => {
          setTypeError("");
        }, 5000);
      }
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const newValues = selectedFieldes.includes(value)
      ? selectedFieldes.filter((field) => field !== value) // Remove if already selected
      : [...selectedFieldes, value]; // Add if not selected

    if (newValues.length >= 1) {
      setCheckboxError("");
      onSetNumber({ selectedFieldes: newValues });
    } else {
      setCheckboxError("Please select at least one type");
      setTimeout(() => {
        setCheckboxError("");
      }, 3000);
    }
  };

  const handleSelect = (value) => {
    onSetSelected(value);
    let fields = [];
    if (value === "BOTH") {
      fields.push("BCP", "FCP");
    } else if (value === "ALLFAM") {
      fields.push("SF", "MF", "DF", "CF", "EF");
    } else {
      fields.push(value);
    }
    onSetApp({ selectedIncludes: fields, selectedNumberFilter: value });
  };

  return (
    <div className="number-section">
      <div className="text-left ml-2">
        <span className="label-text">Type</span>
      </div>
      <div className="d-flex radio-types-flex">
        <div className="d-flex justify-content-between align-items-center w-100 mt-2">
          {Object.keys(fields).map((item, index) => (
            <div
              key={index}
              className="d-flex align-items-center gap-2 cursor-pointer"
            >
              <input
                type="checkbox"
                id={item}
                value={item}
                checked={selectedFieldes.includes(item)}
                onChange={handleChange}
                className={`checkbox-input cursor-pointer ${
                  selectedFieldes.includes(item) ? "checkbox-selected" : ""
                }`}
              />
              <label htmlFor={item} className="m-0 cursor-pointer">
                {fields[item]}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {checkboxError && (
          <div className="checkbox-error-msg">{checkboxError}</div>
        )}
      </div>
      <div className="text-left ml-2 mt-4">
        <span className="label-text">Includes</span>
      </div>
      <div className="select-div ">
        <RwireSelectInput
          data={includeFields}
          onSelect={handleSelect}
          selected={selected}
        />
      </div>

      <div className="text-left ml-2 mt-2">
        <span className="label-text">File</span>
      </div>

      <div>
        <div className="d-flex ml-2 mt-1 file-upload">
          <input
            type="file"
            onChange={handleFileChange}
            className="number-upload-input"
            id="fileInput"
          />
          <div
            className="upload-btn"
            onClick={() => document.getElementById("fileInput").click()}
          >
            <BiUpload alt="edit" className="img-fluid" width="30" height="30" />
            Upload
          </div>
          <div className="file-name" title={fileName}>
            {fileName ? fileName : "<File name>"}
          </div>
        </div>
      </div>

      <div className="text-left ml-2 mt-3 file-size">
        <div id="exceed-file-limit" className="text-danger"></div>
        <span id="final-result">
          (File Type: only TXT, CSV is allowed up to 2MB)
        </span>

        {sizeError && <div className="error">{sizeError}</div>}
        {typeError && <div className="error">{typeError}</div>}
      </div>
    </div>
  );
};

export default NumberSection;
