import React, { useState } from "react";
import "./dashboard.scss";
import Header from "./header";
import MainContent from "./mainComponent";
import GraphsSection from "./graphsSection";
// import StringsSection from "./stringsSection";
import CitationSection from "./citationSection";
import RwireReportRelevantResults from "../../container/report/rwire-report-relevant-results";
import NewKeyFeatures from "../../container/report/new-key-features";
import RwireLoader from "../../container/rwire-loader";
import RwireReportString from "../../container/report/rwire-report-string";
import { Button } from "../../ui-components";
import { InsightsIcon, ReportBtnIcon } from "../../assets/images/report";

const Dashboard = (props) => {
  const [section, setSection] = useState("first");

  // Render different components based on the section value
  const renderSection = () => {
    switch (section) {
      case "first":
        return (
          <>
            <NewKeyFeatures {...props} />
            <RwireReportRelevantResults {...props} />
            <GraphsSection isOpenSidebar={true} {...props} />
          </>
        );
      case "second":
        return (
          <>
            <RwireReportString {...props} />
            <CitationSection {...props} />
          </>
        );
      case "third":
        return (
          <>
            <MainContent {...props} />
          </>
        );

      default:
        return <MainContent {...props} />; // Fallback to the default section
    }
  };

  return (
    <div className="report-new-dashboard">
      <RwireLoader />
      <Header section={section} setSection={setSection} {...props} />
      {renderSection()}
      <div className="position-sticky bottom-0 bg-white py-2 px-4 d-flex gap-3 report-new-footer">
        {/* <button class="insights-btn ms-auto">
          <img src={InsightsIcon} alt="" />
          Insights
        </button> */}

        <button class="report-btn ms-auto disabled-report-btn">
          <img src={ReportBtnIcon} alt="" />
          Report
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
