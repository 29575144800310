/* eslint-disable no-console */
import { multipleFieldsShortCode } from "../resources/data/combine-field-list";

const dateNumberList = [
  "AD",
  "AY",
  "ED",
  "EPRD",
  "EPRY",
  "PCTAD",
  "PCTPD",
  "PD",
  "PRD",
  "PRY",
  "PY",
  "ACN",
  "APCN",
  "CLCN",
  "INCN",
];

/**
 *  cleanQuery takes query as input and replace speacial charachter
 *  with operator, It ignores specialCharacters which comes between double qoutes ("")
 */
export const cleanQuery = (query) => {
  const specialCharacters = {
    "|": "OR",
    "~": "NOT",
    "&": "AND",
    ":": "",
    ";": "",
  };
  let queryArray = query.split("");
  let inQuotes = false;
  queryArray.forEach((q, index) => {
    if (Object.keys(specialCharacters).includes(q) && !inQuotes) {
      if (q === ";") {
        if (index !== queryArray.length - 1) {
          queryArray[index] = specialCharacters[q];
        }
      } else {
        queryArray[index] = specialCharacters[q];
      }
    }
    if (q === '"') inQuotes = !inQuotes;
  });
  query = queryArray.join("");
  return query.replaceAll(/\s+/g, " ").trim();
};

export const checkAsterickConditions = (query) => {
  // eslint-disable-next-line no-useless-escape
  const starMatchRegex = /(?<![a-zA-Z\d/?])\*(?![a-zA-Z\d?])/;
  let starMatch = starMatchRegex.test(query);

  return starMatch;
};
export const checkSpecialConditions = (query) => {
  const matchData = query.match(
    new RegExp('(\\*\\?|\\?\\*|\\*\\*|"\\?|\\?"|\\*"|"\\*|\\*\\(|\\)\\*)'),
    "gi"
  );
  const matchConsicutiveQuestionMarks = query.match(
    new RegExp("(\\?{6,})", "gi")
  );
  if (query.includes("*") && checkAsterickConditions(query)) {
    throw new Error("Please input a valid query.");
  }
  if (matchData) {
    throw new Error(`Consecutive operators are not allowed ${matchData[0]}.`);
  }

  if (matchConsicutiveQuestionMarks) {
    throw new Error(`More than 5 question marks are not allowed.`);
  }
};

const parseExpression = (expression) => {
  const result = [];

  // Replace ?? with a unique placeholder to avoid conflict with regex
  let formattedExpr = expression.replace(/\?\?/g, "__PLACEHOLDER__");

  // Regex to match field and operator
  const regex = /([A-Za-z]+)\s*([><=!]+)\s*\(.*?\)/g;
  let match;

  while ((match = regex.exec(formattedExpr)) !== null) {
    const field = match[1];
    const operator = match[2].replace("__PLACEHOLDER__", "??");

    // Push the result if it's not already in the array
    if (
      !result.some((item) => item.field === field && item.operator === operator)
    ) {
      result.push({
        field: field,
        operator: operator,
      });
    }
  }

  return result;
};

// Function to check if query data uses correct fields with >= and <= operators
export const checkQueryDataWithFields = (query) => {
  const output = parseExpression(query);

  output.forEach(({ field, operator }) => {
    if (
      operator === ">=" ||
      operator === "<=" ||
      operator === ">" ||
      operator === "<"
    ) {
      // If the field is not in dateNumberList, throw an error
      if (!dateNumberList.includes(field)) {
        throw new Error(
          `You have entered a numeric operator like < > or = in a non-numeric field. Please select another operator and resubmit your query.`
        );
      }
    }
  });
};
