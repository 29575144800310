import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  createReportProject,
  getReportWorkspaceElements,
  resetProjectData,
  setProjectReport,
  getReportProjects,
  getBackwardCitedPatentsList,
} from "../../action/report/report-project";
import { getAllProjectUsers } from "../../action/report/report-users";
import { checkIfPatentAvailable } from "../../action/keyword-suggestor";
import CreateProjectModal from "../../components/report/existing-project/create-project-modal";

const Container = (props) => {
  // return <CreateProject {...props} />;
  return <CreateProjectModal {...props} />;
};

const mapStateToProps = ({ app: { userProjects } }) => ({
  userProjects,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetReportProjects: getReportProjects,
      onCreateReportProject: createReportProject,
      onGetReportWorkspaceElements: getReportWorkspaceElements,
      onResetProjectData: resetProjectData,
      onSetProjectReport: setProjectReport,
      onGetAllProjectUsers: getAllProjectUsers,
      onCheckIfPatentAvailable: checkIfPatentAvailable,
      onGetBackwardCitedPatentsList: getBackwardCitedPatentsList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
