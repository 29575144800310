import React, { useEffect } from "react";
import FamilyLink from "../../../src/components/common/patents-result-common/rwire-familylink";
function Family(props) {
  const {
    simpleFamily = [],
    mainFamily = [],
    domesticFamily = [],
    completeFamily = [],
    extendedFamily = [],
    extraClass = "",
    onSetFilter,
  } = props;
  useEffect(() => {
    simpleFamily.join(" | ").replace(/| *$/, "");
  });
  return (
    <>
      {simpleFamily.length > 0 &&
      mainFamily.length > 0 &&
      domesticFamily.length > 0 &&
      completeFamily.length > 0 &&
      extendedFamily.length > 0 ? (
        <div className={`view-tables ${extraClass ? extraClass : ""}`}>
          <table className="legal__events">
            <thead className="table">
              <tr>
                <th>Family Type</th>
                <th>Family Members</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Simple</td>
                <td>
                  {simpleFamily?.map((data, index) => (
                    <FamilyLink
                      familyList={data}
                      onSetFilter={onSetFilter}
                      index={index}
                      length={simpleFamily?.length}
                    />
                  ))}
                </td>
              </tr>
              <tr>
                <td>Main</td>
                <td>
                  {mainFamily?.map((data, index) => (
                    <FamilyLink
                      familyList={data}
                      onSetFilter={onSetFilter}
                      index={index}
                      length={mainFamily?.length}
                    />
                  ))}
                </td>
              </tr>
              <tr>
                <td>Domestic</td>
                <td>
                  {domesticFamily?.map((data, index) => (
                    <FamilyLink
                      familyList={data}
                      onSetFilter={onSetFilter}
                      index={index}
                      length={domesticFamily?.length}
                    />
                  ))}
                </td>
              </tr>
              <tr>
                <td>Complete</td>
                <td>
                  {completeFamily?.map((data, index) => (
                    <FamilyLink
                      familyList={data}
                      onSetFilter={onSetFilter}
                      index={index}
                      length={completeFamily?.length}
                    />
                  ))}
                </td>
              </tr>
              <tr>
                <td>Extended</td>
                <td>
                  {extendedFamily?.map((data, index) => (
                    <FamilyLink
                      familyList={data}
                      onSetFilter={onSetFilter}
                      index={index}
                      length={extendedFamily?.length}
                    />
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <p className="pt-2">Data not available</p>
      )}
    </>
  );
}

export default Family;
