import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getBackwardCitationsData,
  getReportProjectDataById,
  getReportProjects,
  resetProjectData,
  setProjectReport,
} from "../../action/report/report-project";
import { setApp } from "../../action/app";
import ProjectDropdown from "../../pages/rwire-home/project-dropdown";
import { getAllProjectUsers } from "../../action/report/report-users";

const Container = (props) => {
  return <ProjectDropdown {...props} />;
};

const mapStateToProps = ({ app, project }) => ({
  ...app,
  ...project,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetReportProjects: getReportProjects,
      onSetApp: setApp,
      onSetProjectReport: setProjectReport,
      onResetProjectData: resetProjectData,
      onGetReportProjectDataById: getReportProjectDataById,
      onGetAllProjectUsers: getAllProjectUsers,
      onGetBackwardCitationsData: getBackwardCitationsData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
