import { Pagination } from "rsuite";
import { filterHighlightData } from "../../action/quick-view";
import SingleHighlightedFieldData from "./single-highlighted-field-data";
import "./styles.scss";
import { useState } from "react";
import RwireLoader from "../../container/rwire-loader";

const QuickViewBlock = (props) => {
  const {
    quickViewHighlights,
    totalRecordsCount,
    dataSize,
    activePages,
    jumpPage,
    handleJumpPage,
    onSetResultTable,
    onPerformSearch,
    selectedIncludes,
  } = props;

  const [prev] = useState(true);
  const [next] = useState(true);
  const [first] = useState(true);
  const [last] = useState(true);
  const [ellipsis] = useState(true);
  const [boundaryLinks] = useState(true);
  const [size] = useState("xs");
  const [maxButtons] = useState(5);
  const [layout] = useState(["pager"]);
  const [isLoad, setLoad] = useState(false);

  const handleChangePage = async (e) => {
    // tableRef.current.scrollTop = 0;
    setLoad(true);
    await onSetResultTable({
      dataFrom: (parseInt(e) - 1) * dataSize,
      activePages: e,
    });
    await onPerformSearch("", {
      isNumberWithIncludeSearch:
        selectedIncludes && selectedIncludes.length > 0 ? true : false,
      isReRunId: true,
      runAggregate: false,
    });
    setLoad(false);
  };

  return (
    <>
      <div className="quick-view-table">
        <div className="d-flex header-block">
          <div className=" text-center py-2 pn-header-block">Patent Number</div>
          <div className=" text-center py-2 details-header-block">
            <span>Details</span>
          </div>
        </div>
        <div className="quick-view-list">
          {isLoad ? (
            <RwireLoader />
          ) : (
            quickViewHighlights.map((item, k) => {
              return (
                <div className="d-flex qv-row">
                  <div className="pn-number-block text-center pt-3">
                    {item._id}
                  </div>
                  <div className="quick-view-details-block p-3">
                    {Object.keys(item.highlight).map((field) => (
                      <SingleHighlightedFieldData
                        displayData={filterHighlightData(
                          quickViewHighlights?.[k]?.highlight
                            ? quickViewHighlights?.[k]?.highlight
                            : []
                        )}
                        selectedIndex={k}
                        field={field}
                        key={`${field}-${k}`}
                      />
                    ))}
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className="quick-view-pagination">
          <Pagination
            layout={layout}
            size={size}
            prev={totalRecordsCount >= 50 ? prev : null}
            next={totalRecordsCount >= 50 ? next : null}
            first={first}
            last={last}
            ellipsis={ellipsis}
            boundaryLinks={boundaryLinks}
            total={totalRecordsCount > 10000 ? 10000 : totalRecordsCount}
            limit={dataSize}
            maxButtons={maxButtons}
            activePage={activePages}
            onChangePage={handleChangePage}
            jumpPage={jumpPage}
            onJumpPage={handleJumpPage}
          />
        </div>
      </div>
    </>
  );
};

export default QuickViewBlock;
