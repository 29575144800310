const initialState = {
  queryError: false,
  patentOrPublication: "patent",
  fieldsOrExpert: "fields",
  publicationOrApplication: "publication",
  fieldList: {},
  filterField: [],
  headCols: [],
  selectedFields: [],
  isResult: false,
  isLoadingTable: false,
  isResetRules: false,
  headerRedirection: false,
  cognizanceOrAnalyse: "cognizance",
  queryEvaluatorText: "",
  queryEvaluatorExpertText: "",
  isInActiveUser: false,
  searchQuery: "",
  searchedVisibleQuery: "",
  userName: "",
  userEmailId: "",
  selectedField: "",
  clickedOnEditQuery: true,
  checkedFields: ["PN"],
  clickedOnField: true,
  finalFileData: "",
  selectedIncludes: [],
  selectedNumberFilter: "",
  collapsableFields: "PN_B",
  isUpdateQuery: false,
  activeTab: "fielded",
  searchedTab: "fielded",
  currentPage: "home",
  isEditModalOpen: false,
  isActiveEditModal: false,
  previousPage: "rwire-patents",
  selectedYear: 0,
  isDarkMode: false,
  isProjectMode: sessionStorage.getItem("projectMode") === "true" || false,
  isSearchFromIncremental: false,
  userRole: "",
  userProjectAccess: "",
  currentFieldedCursorLocation: null,
  showFilters: true,
  editEnableOnSave: false
};

const app = (state = initialState, { type, payload }) => {
  switch (type) {
    case "APP_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default app;
