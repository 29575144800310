import React, { useCallback, useEffect } from "react";
import logo from "../../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useStore } from "react-redux";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import RwireToggleButton from "../../darkmode-button/toggle-button";
import NavDropdown from "react-bootstrap/NavDropdown";
import logoutIcon from "../../../images/logout.svg";
import RWIRE_IMAGES from "../../common/common-functions/rwire-images";
import { resetFields } from "../../../resources/data/options";
import { clearFielded } from "../../../common/clear-filded";
import "../../../assets/css/style.scss";
import { MdDeleteSweep } from "react-icons/md";
import ReportModeToggle from "../../report/report-mode-toggle";
import { ReportsRoutes } from "../../report/rwire-dashboard-wrapper/rwire-dashboard-wrapper";
import { ACCESS_VIEWER } from "./../../../common/user-roles";
function RWireHeader(props) {
  const {
    setAllField,
    currentPage,
    onSetApp,
    isDarkMode,
    onSetFullView,
    onDeleteUserAnnotations,
    isProjectMode,
    onGetReportProjectDataById,
    onSetProjectReport,
    onGetAllProjectUsers,
    onGetBackwardCitationsData,
  } = props;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const store = useStore();
  const isPatentView = pathname.includes("/en/patent-view");

  const isAuthenticate = !!sessionStorage.getItem("token");
  const {
    app: { userName, userProjectAccess },
    project: { workspaceElements },
  } = store.getState();

  const viewerModeHeaderLinkTo = () => {
    if (pathname.includes("/en/report") && userProjectAccess !== ACCESS_VIEWER)
      return "/en";
    if (pathname.includes("/en/report") && userProjectAccess === ACCESS_VIEWER)
      return;
    else return projectLink;
  };
  const viewerModeHeaderLinkContent = () => {
    if (pathname.includes("/en/report") && userProjectAccess !== ACCESS_VIEWER)
      return <Trans>Back to Search</Trans>;
    if (pathname.includes("/en/report") && userProjectAccess === ACCESS_VIEWER)
      return;
    else return <Trans>Back to Project</Trans>;
  };

  const handleClick = useCallback(() => {
    sessionStorage.clear();
    localStorage.removeItem("isLoggedIn");
    navigate("/en/login");
  }, [navigate]); // Ensure navigate is in the dependency array

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "isLoggedIn" && event.newValue === "false") {
        handleClick();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [handleClick]); // This dependency is now stable

  const handleClickDownloadCenter = () => {
    return navigate("/en/download-center");
  };

  const handleResetProps = () => {
    const clearData = clearFielded(resetFields);
    setAllField({
      queryFields: clearData,
      tabWiseSearchQuery: { fielded: "", expert: "", number: "" },
      syntaxqueryError: "",
      syntaxqueryCorrect: "",
    });
    onSetApp({ searchQuery: "", smartSearchWord: "", editQuery: "" });
  };
  const handleClickOnHistory = (e) => {
    if (currentPage !== "history") {
      handleResetProps();
    }
  };
  const handleClickOnHome = (e) => {
    if (
      pathname === "/en/about-us" ||
      pathname === "/en/services" ||
      pathname === "/en/faq"
    ) {
      navigate("/en/login");
    } else {
      handleResetProps();
    }
  };
  const handleClickOnSearch = (e) => {
    if (currentPage === "history") {
      handleResetProps();
    }
  };

  const handleDeleteAnnotations = () => {
    onDeleteUserAnnotations();
    onSetFullView({
      userAnnotations: [],
      stringsHighlights: [],
      stringsComments: [],
    });
  };
  const handleToggle = () => {
    onSetApp({ isDarkMode: !isDarkMode });
  };
  useEffect(() => {
    const getElements = async (id) => {
      const elements = await onGetReportProjectDataById(id);
      onSetProjectReport({
        workspaceElements: elements.workspaceElements.split(",").map(Number),
        projectName: elements.name,
      });
      await onGetBackwardCitationsData();
    };

    const getUsersOfProject = async (id) => {
      await onGetAllProjectUsers(id);
    };
    const id = sessionStorage.getItem("projectId");
    if (id) {
      getElements(id);
      getUsersOfProject(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let projectLink = "";
  if (workspaceElements.length > 0) {
    // projectLink = ReportsRoutes[parseInt(workspaceElements[0]) - 1].to;
    projectLink = "/en/report";
  }
  return (
    <div
      className={`container-fluid sticky-top header-bg shadow-sm ${
        isAuthenticate ? "dark-bg" : ""
      }`}
      id="rwire-header"
    >
      <div className="px-4 w-100">
        <nav className="navbar width-adjust-navbar navbar-expand-lg header-bg navbar-light py-3 py-lg-0">
          <div onClick={handleClickOnHome}>
            {userProjectAccess !== ACCESS_VIEWER ? (
              <Link
                to={`${isAuthenticate ? "/en" : ""}`}
                className="navbar-brand"
              >
                <img src={logo} alt="Rwire logo" className="rwire-logo" />
              </Link>
            ) : (
              <div className="navbar-brand">
                <img src={logo} alt="Rwire logo" className="rwire-logo" />
              </div>
            )}
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            {isAuthenticate ? (
              <div className="navbar-nav ms-auto py-0 ml-auto ">
                {!isPatentView &&
                  sessionStorage.getItem("projectId") &&
                  isProjectMode && (
                    <div className="my-auto">
                      <ReportModeToggle
                        onSetApp={onSetApp}
                        isProjectMode={isProjectMode}
                      />
                    </div>
                  )}
                {!isPatentView &&
                  !pathname.includes("/en/report") &&
                  pathname !== "/en" &&
                  pathname !== "/en/" && (
                    <div onClick={handleClickOnSearch}>
                      <Link
                        to="/en"
                        className={`nav-item nav-link ${
                          pathname === "/en" ? "active" : ""
                        } ${
                          sessionStorage.getItem("projectMode") === "true" &&
                          sessionStorage.getItem("userProjectAccess") ===
                            "ACCESS_VIEWER"
                            ? "d-none"
                            : " "
                        }`}
                      >
                        <Trans>{t("header_search")}</Trans>
                      </Link>
                    </div>
                  )}
                {!isPatentView &&
                  sessionStorage.getItem("projectId") &&
                  isProjectMode && (
                    <div>
                      <Link
                        to={viewerModeHeaderLinkTo()}
                        className={`nav-item nav-link`}
                      >
                        {viewerModeHeaderLinkContent()}
                      </Link>
                    </div>
                  )}
                {!isPatentView && (
                  <Navbar.Collapse id="navbar-dark-example">
                    <Nav>
                      <NavDropdown
                        id="nav-dropdown-dark-example"
                        title={<Trans>Project Mode</Trans>}
                        menuVariant="dark"
                        className="logout-dropdown"
                      >
                        <NavDropdown.Item
                          onClick={() => navigate("/en/existing-project")}
                        >
                          <span>Existing Project</span>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                )}
                {!isPatentView && userProjectAccess !== ACCESS_VIEWER ? (
                  <div onClick={handleClickOnHistory}>
                    <Link
                      to="/en/history"
                      className={`nav-item nav-link ${
                        pathname === "/en/history" ? "active" : ""
                      }`}
                    >
                      <Trans>{t("rwire_history")}</Trans>
                    </Link>
                  </div>
                ) : (
                  <div className="nav-item nav-link"></div>
                )}
                {!isPatentView && (
                  <Navbar.Collapse id="navbar-dark-example">
                    <Nav>
                      <NavDropdown
                        id="nav-dropdown-dark-example"
                        title={
                          <Trans>
                            {t("rwire_username")}, {userName}
                          </Trans>
                        }
                        menuVariant="dark"
                        className="logout-dropdown"
                      >
                        <NavDropdown.Item onClick={handleClickDownloadCenter}>
                          <img
                            src={RWIRE_IMAGES.RwireDownload}
                            alt="logout"
                            className="me-2"
                            style={{ width: "20px", height: "20px" }}
                          />
                          <span>{t("rwire_download_center")}</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={handleClick}>
                          <img
                            src={logoutIcon}
                            alt="logout"
                            className="me-2 "
                          />
                          <span>{t("rwire_logout")}</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={handleDeleteAnnotations}>
                          <MdDeleteSweep
                            alt="logout"
                            className="me-2 clear-annotations-button"
                          />
                          <span>Clear All</span>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                )}
              </div>
            ) : (
              <div className="navbar-nav ms-auto py-0 ml-auto ">
                <Link
                  to="/en/about-us"
                  className={`nav-item nav-link ${
                    pathname === "/en/about-us" ? "active" : ""
                  }`}
                >
                  <Trans>{t("header_about")}</Trans>
                </Link>
                <Link
                  to="/en/services"
                  className={`nav-item nav-link ${
                    pathname === "/en/services" ? "active" : ""
                  }`}
                >
                  <Trans>{t("header_services")}</Trans>
                </Link>
                <Navbar.Collapse id="navbar-dark-example">
                  <Nav>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title={<Trans>{t("english_language")}</Trans>}
                      menuVariant="dark"
                      className="logout-dropdown"
                    >
                      <NavDropdown.Item>
                        <span>{t("english_language")}</span>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
              </div>
            )}
          </div>
          <div className="ml-1">
            <RwireToggleButton
              handleToggle={handleToggle}
              onSetApp={onSetApp}
            />
          </div>
        </nav>
      </div>
    </div>
  );
}
export default RWireHeader;
