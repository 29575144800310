const initialState = {
  projectName: "",
  workspaceElements: [],
  projectData: {},
  projectUsers: [],
  existingOrganizationalUsers: [],
  projectType: "",
  projectPatentNumber: "",
  projectTechnology: "",
  citedPatents: [],
  citedReferences: [],
  selectedStrings: [],
};

const project = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_PROJECT":
      return { ...initialState };

    case "PROJECT_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default project;
