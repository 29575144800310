import { patent_detials } from "../../components/constant";
import { search } from "../../service/elasticSearch";
import { fetch } from "../../service/fetch";

export const setReportRelevantResult = (payload) => ({
  type: "SET_RELEVANT_RESULTS",
  payload: {
    ...payload,
  },
});

export const addRelevantResult =
  ({
    patentNumber,
    title,
    priorityDate,
    publicationDate,
    comment,
    projectId,
  }) =>
  async (dispatch) => {
    try {
      const { id } = await fetch(
        {
          url: "/api/project-relavent-results",
          body: {
            patentNumber,
            title,
            priorityDate,
            publicationDate,
            comment,
            projectId,
          },
        },
        "POST"
      );

      dispatch(
        setReportRelevantResult({ isPatentNumberInRelevantResult: true })
      );
      return id;
    } catch (e) {}
  };

export const getReportProjectRelevantResultFromPN =
  ({ projectId, patentNumber = "", selectedId }) =>
  async (dispatch) => {
    try {
      let url = `/api/project-relavent-results/project/${projectId}`;
      if (patentNumber) {
        url = `${url}?patentNumber=${patentNumber}`;
      }
      if (selectedId.length) {
        url = `/api/project-relavent-results/project/${projectId}?keyFeatureIds=${selectedId.join(
          ","
        )}`;
      }
      const { result: data } = await fetch(
        {
          url,
        },
        "GET"
      );

      const isPatentNumberInRelevantResult = data.length > 0;

      dispatch(
        setReportRelevantResult({
          isPatentNumberInRelevantResult,
          relevantResultReportTableData: data,
        })
      );
    } catch (e) {}
  };

export const updateReportProjectRelevantResultRow =
  ({ id, body, publicationCount, applicationCount, order }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-relavent-results/${id}`,
          body,
          publicationCount,
          applicationCount,
          order,
        },
        "PUT"
      );
    } catch (error) {}
  };

export const deleteRow =
  ({ patentNumber, id }) =>
  async (dispatch, getState) => {
    try {
      const {
        reportRelevantResults: { relevantResultReportTableData = [] },
      } = getState();
      const projectId = sessionStorage.getItem("projectId");
      await fetch(
        {
          url: `/api/project-relavent-results/${projectId}/${patentNumber}`,
        },
        "DELETE"
      );

      const updatedData = relevantResultReportTableData.filter(
        (i) => i.id !== id
      );

      dispatch(
        setReportRelevantResult({ relevantResultReportTableData: updatedData })
      );
    } catch (error) {}
  };

export const removeFromRelevantResult =
  ({ projectId, patentNumber }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-relavent-results/${projectId}/${patentNumber}`,
        },
        "DELETE"
      );
      dispatch(
        setReportRelevantResult({ isPatentNumberInRelevantResult: false })
      );
    } catch (error) {}
  };

export const addKeyFeaturesRelevantResult =
  ({ relevantResultId, addKeyFeaturesId, removeKeyFeaturesId, projectId }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: "/api/project-relavent-key-feature",
          body: {
            relevantResultId,
            addKeyFeaturesId,
            removeKeyFeaturesId,
            projectId,
          },
        },
        "POST"
      );

      // dispatch(
      //   setReportRelevantResult({  }),
      // );
    } catch (e) {}
  };

export const fetPatentDetails = (id) => async (dispatch, getState) => {
  const {
    reportRelevantResults: { relevantResultReportTableData = [] },
  } = getState();

  const isAlreadyAdded = relevantResultReportTableData.filter(
    (i) => i.patentNumber === id
  );
  let resultId = "";

  if (!isAlreadyAdded.length) {
    const queryObj = {
      queryToSearch: id,
    };
    const body = JSON.stringify(queryObj);
    const projectId = sessionStorage.getItem("projectId");
    const dataResponse = await search(body, patent_detials);

    if (dataResponse.data) {
      const data = {
        ...dataResponse.data,
      };
      const addedPN = {
        id: id,
        patentNumber: id,
        title: data["TI_EN"] ? data["TI_EN"] : "",
        priorityDate: data["EPRD"] ? data["EPRD"] : "",
        publicationDate: data["PD"] ? data["PD"] : "",
        comment: "",
        projectRelevantResultKeyFeatureData: [],
      };
      resultId = await dispatch(
        addRelevantResult({
          patentNumber: id,
          title: data["TI_EN"] ? data["TI_EN"] : "",
          priorityDate: data["EPRD"] ? data["EPRD"] : "",
          publicationDate: data["PD"] ? data["PD"] : "",
          comment: "",
          projectId,
        })
      );

      const updatedData = [{ ...addedPN }, ...relevantResultReportTableData];
      dispatch(
        setReportRelevantResult({
          relevantResultReportTableData: updatedData,
        })
      );

      return { resultId, alreadyExists: false };
    } else {
      resultId = await dispatch(
        addRelevantResult({
          patentNumber: id,
          title: "",
          priorityDate: "",
          publicationDate: "",
          comment: "",
          projectId,
        })
      );

      const updatedData = [
        {
          patentNumber: id,
          title: "",
          priorityDate: "",
          publicationDate: "",
          projectRelevantResultKeyFeatureData: [],
          comment: "",
        },
        ...relevantResultReportTableData,
      ];
      dispatch(
        setReportRelevantResult({
          relevantResultReportTableData: updatedData,
        })
      );
      return { resultId, alreadyExists: false };
    }
  } else {
    resultId = isAlreadyAdded[0].id;
    return { resultId, alreadyExists: true };
  }
};
