import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import LayoutWrapper from "../layout-wrapper";
import { getUserProfile } from "../action/app";
import { setQuery } from "../action/search-query";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Container = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isExcludedPage = [
      "reset-password",
      "forgot-password",
      "login-page",
      "service-page",
      "aboutus-page",
      "faq-page"
    ].includes(props.titleKey);

    if (!isExcludedPage) {
      const tokenExists = sessionStorage.getItem("token");

      if (!tokenExists) {
        navigate("/en/login");
        return;
      }

      props.onGetUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <LayoutWrapper {...props} />;
};

const mapStateToProps = ({ app: { userName } }) => ({
  userName,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetUserProfile: getUserProfile,
      onSetQuery: setQuery,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Container);
